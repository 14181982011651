import { createFileRoute } from '@tanstack/react-router';
import clsx from 'clsx';

import { getAsyncListState } from '~/shared/components/AsyncList';
import { Button, ButtonProps } from '~/shared/components/Button';
import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';
import { IconVariants } from '~/shared/components/Icon';
import { Input, InputVariants } from '~/shared/components/Input';
import { wrapJsxIf } from '~/shared/helpers/render';
import { useDebouncedSearch } from '~/shared/hooks/useDebouncedSearch';
import {
  ExtractSearchParamsFromRoute,
  useSearchParamsState,
} from '~/shared/hooks/useSearchParamsState';

import {
  SearchableSearchParams,
  WithSearchParamsValidation,
} from '~/services/navigation';

import {
  useEditSemenDoseModal,
  useSemenDosesPaginatedQuery,
} from '~/entities/semenDoses';

import { PageHeader } from '~/features/layouts';

import layoutStyles from '~/styles/modules/layout.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

import { SemenDosesTable } from './components/SemenDosesTable';

const SEMEN_DOSES_ROWS_DEFAULT_COUNT = 8;

export const Route = createFileRoute(
  '/$companyId/_layout/user/entities/semenDoses/'
)({
  wrapInSuspense: true,
  component: SemenDosesPage,
  validateSearch: ({
    search,
  }: WithSearchParamsValidation<SearchableSearchParams>) => ({
    search: search ?? '',
  }),
});

function SemenDosesPage() {
  const { open: openEditSemenDoseModal } = useEditSemenDoseModal();

  const { search: debouncedSearch, setSearch: setDebouncedSearch } =
    useSearchParamsState<ExtractSearchParamsFromRoute<typeof Route>>();

  const { search, setSearch, isSearchActive } = useDebouncedSearch({
    defaultSearch: debouncedSearch,
    onDebouncedSearchChange: setDebouncedSearch,
  });

  const queryVariables = {
    search: debouncedSearch,
  };

  const { items: semenDoses, ...asyncProps } = useSemenDosesPaginatedQuery({
    variables: queryVariables,
  });

  const addButtonProps = {
    iconVariant: IconVariants.plus,
    children: 'Добавить поставку',
    onPress: () => openEditSemenDoseModal(),
  } satisfies ButtonProps;

  const filtersElement = (
    <div className={clsx('flex gap-16 mb-24', layoutStyles.limitedContainer)}>
      <Input
        {...{
          name: 'search',
          placeholder: 'Поиск',
          value: search,
          onValueChange: setSearch,
          className: 'default-control',
          variant: InputVariants.search,
        }}
      />
    </div>
  );

  const asyncListState = getAsyncListState({
    items: semenDoses,
    skeletonItemsCount: SEMEN_DOSES_ROWS_DEFAULT_COUNT,
    isSearchActive,
    isLoading: asyncProps.isLoading,
  });

  const tableElement = (
    <SemenDosesTable
      {...{
        asyncListState,
        semenDoses,
        queryVariables,
        isSearchActive,
        filtersElement,
        shouldNoItemsMessageHideTable: true,
        noItemsMessage: (
          <div
            className={clsx(
              layoutStyles.fillLeftoverHeightContainer,
              layoutStyles.limitedContainer,
              panelStyles.panel,
              'p-24 grid place-items-center'
            )}
          >
            <DataBlockedMessage
              {...{
                isLarge: true,
                className: 'p-24',
                message: 'Поставки семени пока не добавлены',
                buttonProps: addButtonProps,
              }}
            />
          </div>
        ),
        noSearchItemsMessage: 'Поставки семени не найдены',
        noSearchItemsDescription:
          'По вашему запросу нет подходящих поставок семени. Используйте другое название',
        ...asyncProps,
      }}
    />
  );

  return (
    <>
      <PageHeader
        {...{
          title: 'Поставка семени',
          className: layoutStyles.limitedContainer,
          rightContent: !asyncListState.isItemsNotCreated && (
            <Button key="addSemenDose" {...addButtonProps} />
          ),
        }}
      />

      {wrapJsxIf(
        tableElement,
        !asyncListState.isItemsNotCreated,
        <div
          className={clsx(
            panelStyles.largePanel,
            layoutStyles.limitedContainer,
            'p-24'
          )}
        />
      )}
    </>
  );
}
