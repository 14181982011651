import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type InseminationSchemeFragment = { __typename: 'InseminationScheme', id: string, name: string, code: string, isDefault: boolean };

export const InseminationSchemeFragmentDoc = gql`
    fragment InseminationScheme on InseminationScheme {
  __typename
  id
  name
  code
  isDefault
}
    `;