import R from 'ramda';

import { makeUsePaginatedQuery } from '~/services/gql';

import { InseminationSchemeFragment } from '../gql/fragments/inseminationScheme.graphql';
import {
  InseminationSchemesQuery,
  InseminationSchemesQueryVariables,
  useInseminationSchemesQuery,
} from '../gql/queries/inseminationSchemes.graphql';

export const useInseminationSchemesPaginatedQuery = makeUsePaginatedQuery<
  InseminationSchemeFragment,
  InseminationSchemesQuery,
  InseminationSchemesQueryVariables
>({
  useQuery: useInseminationSchemesQuery,
  getItemsFromQueryData: data =>
    data.inseminationSchemes.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.inseminationSchemes.pageInfo,
});
