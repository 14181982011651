import React, { useEffect, useMemo, useState } from 'react';

import clsx from 'clsx';
import R from 'ramda';
import { match } from 'ts-pattern';

import { getAsyncListState, useAsyncList } from '~/shared/components/AsyncList';
import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';
import { FunctionButton } from '~/shared/components/FunctionButton';
import { IconVariants } from '~/shared/components/Icon';
import { Skeleton, TextSkeletonSizes } from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { mergeProps, mergeRefs } from '~/shared/helpers/mergeProps';
import { useCustomScrollWrapper } from '~/shared/hooks/useCustomScrollWrapper';
import { usePrevious } from '~/shared/hooks/usePrevious';

import { HiddenDataCaption } from './components/HiddenDataCaption';
import { TableRow } from './components/TableRow';
import { getSpanValue } from './helpers';
import styles from './index.module.scss';
import { TableColumnConfigInner, TableProps, TableThemes } from './types';

const defaultGetItemKey = <T extends object>(item: T) =>
  'id' in item ? String(item.id) : '';

export const ITEM_ACTIONS_COLUMN_KEY = 'itemActions';

const MIN_ENTRIES_COUNT_TO_HIDE = 100;
const MIN_ENTRIES_THRESHOLD_TO_HIDE = 50;

const TableInternal = <
  T extends object,
  CellData = undefined,
  SkeletonItemsCount extends number | undefined = number,
>(
  props: TableProps<T, CellData, SkeletonItemsCount>,
  ref: React.Ref<HTMLDivElement>
) => {
  const {
    className,
    theme = TableThemes.largeSecondary,

    items: itemsProp = [],
    skeletonItemsCount,
    getItemKey = defaultGetItemKey,

    renderItemActions,
    printableTitle,

    columnConfigs,
    isTableWithExpandableRows: isTableWithExpandableRowsProp = false,
    getExpandableRows,
    renderExpandableRowContent,
    hasExpandableRowContent = R.always(true),

    withStickyHeader = true,
    shouldNoItemsMessageHideTable = false,
    shouldHideScrollBarsForNoOverflow = true,
    withCustomScroll = true,
    withBorder = false,
    shouldHideLargeData: shouldHideLargeDataProp = false,

    noItemsMessage,
    noSearchItemsMessage,
    noSearchItemsDescription,

    filtersElement,

    ...asyncListProps
  } = props;
  const hasNestedColumnsLayout = columnConfigs.some(
    config => 'nestedColumns' in config
  );
  const nestedHeaderRowSpan = hasNestedColumnsLayout ? 2 : undefined;

  type FlattenTableColumnConfig = TableColumnConfigInner<T, SkeletonItemsCount>;
  const flattenColumnConfigsFull = useMemo(() => {
    return columnConfigs.flatMap<FlattenTableColumnConfig>(config => {
      if (config.nestedColumns) {
        return config.nestedColumns.map<FlattenTableColumnConfig>(
          (nestedConfig, configIndex, configsArray) => {
            const isNestedLeft = !configIndex;
            const isNestedRight = configIndex === configsArray.length - 1;
            return {
              ...nestedConfig,
              isNested: true,
              isNestedLeft,
              isNestedRight,
              groupingColumnConfig: config,
            };
          }
        );
      }
      return [
        {
          ...config,
          isNested: false,
          isNestedLeft: false,
          isNestedRight: false,
        },
      ];
    });
  }, [columnConfigs]);

  const shouldHideLargeDataColumns =
    flattenColumnConfigsFull.length > MIN_ENTRIES_COUNT_TO_HIDE &&
    flattenColumnConfigsFull.length - MIN_ENTRIES_COUNT_TO_HIDE >
      MIN_ENTRIES_THRESHOLD_TO_HIDE;

  const shouldHideLargeDataRows =
    itemsProp.length > MIN_ENTRIES_COUNT_TO_HIDE &&
    itemsProp.length - MIN_ENTRIES_COUNT_TO_HIDE >
      MIN_ENTRIES_THRESHOLD_TO_HIDE;

  const shouldHideLargeData =
    shouldHideLargeDataProp &&
    (shouldHideLargeDataColumns || shouldHideLargeDataRows);

  const [isLargeDataHiddenState, setIsLargeDataHiddenState] =
    useState(shouldHideLargeData);

  const prevColumnConfigsFull = usePrevious(flattenColumnConfigsFull);
  useEffect(() => {
    if (prevColumnConfigsFull !== flattenColumnConfigsFull) {
      setIsLargeDataHiddenState(shouldHideLargeData);
    }
  }, [flattenColumnConfigsFull]);

  const isLargeDataHidden =
    prevColumnConfigsFull === flattenColumnConfigsFull
      ? isLargeDataHiddenState
      : shouldHideLargeData;

  const flattenColumnConfigs = useMemo(() => {
    return isLargeDataHidden
      ? flattenColumnConfigsFull.slice(0, MIN_ENTRIES_COUNT_TO_HIDE)
      : flattenColumnConfigsFull;
  }, [isLargeDataHidden, flattenColumnConfigsFull]);

  const items = useMemo(() => {
    return isLargeDataHidden
      ? itemsProp.slice(0, MIN_ENTRIES_COUNT_TO_HIDE)
      : itemsProp;
  }, [isLargeDataHidden, itemsProp]);

  const hiddenColumnsCount =
    flattenColumnConfigsFull.length - flattenColumnConfigs.length;

  const hiddenRowsCount = itemsProp.length - items.length;

  const expandableRowsCount = items.filter(hasExpandableRowContent).length;

  const isTableWithExpandableRows =
    isTableWithExpandableRowsProp ||
    (!!getExpandableRows && !!expandableRowsCount) ||
    !!renderExpandableRowContent;

  const headerTypographyVariant = match(theme)
    .with(TableThemes.tertiary, R.always(TypographyVariants.descriptionLarge))
    .with(
      TableThemes.smallSecondary,
      R.always(TypographyVariants.descriptionMediumStrong)
    )
    .otherwise(R.always(TypographyVariants.descriptionLargeStrong));

  const cellTypographyVariant = match(theme)
    .with(
      TableThemes.smallSecondary,
      R.always(TypographyVariants.descriptionLarge)
    )
    .otherwise(R.always(TypographyVariants.bodySmall));

  const columnsCount =
    flattenColumnConfigs.length +
    (isTableWithExpandableRows ? 1 : 0) +
    (renderItemActions ? 1 : 0);

  let fullHeaderHeightColumnsToSkip = isTableWithExpandableRows ? 1 : 0;

  const renderHeaderColumn = (
    configToRender: TableColumnConfigInner<T, SkeletonItemsCount>,
    isFirstRow: boolean
  ) => {
    if (
      isFirstRow &&
      configToRender.groupingColumnConfig &&
      !configToRender.isNestedLeft
    ) {
      return null;
    }

    if (
      !isFirstRow &&
      !configToRender.isNested &&
      !configToRender.isSplittedHeader
    ) {
      fullHeaderHeightColumnsToSkip += 1;
      return null;
    }

    const gridColumnStart =
      isFirstRow || !fullHeaderHeightColumnsToSkip
        ? undefined
        : fullHeaderHeightColumnsToSkip + 1;

    if (!isFirstRow) {
      fullHeaderHeightColumnsToSkip = 0;
    }

    const config = isFirstRow
      ? (configToRender.groupingColumnConfig ?? configToRender)
      : configToRender;

    const isGroupingConfig = !!config.nestedColumns;

    const colSpan = config.nestedColumns?.length;
    const rowSpan =
      isFirstRow && !config.nestedColumns && !config.isSplittedHeader
        ? nestedHeaderRowSpan
        : undefined;

    return (
      <td
        key={config.key}
        {...{
          colSpan,
          rowSpan,

          className: clsx(
            styles.headerCell,
            config.isSticky && styles.stickyCell,
            config.columnClassName,
            config.headerClassName,
            {
              [styles.groupedCellLeft]:
                configToRender.isNestedLeft || isGroupingConfig,
              [styles.groupedCellRight]:
                configToRender.isNestedRight || isGroupingConfig,
            }
          ),
          style: {
            gridRow: getSpanValue(rowSpan),
            gridColumnStart,
            gridColumnEnd: getSpanValue(colSpan),
          },
        }}
      >
        <Typography
          {...{
            variant: headerTypographyVariant,
            skeletonSize: TextSkeletonSizes.small,
            ...config.headerTypographyProps,
          }}
        >
          {config.title}
          {!!config.functionButtonProps && (
            <FunctionButton
              {...mergeProps(config.functionButtonProps, {
                className: styles.icon,
              })}
            />
          )}
        </Typography>
      </td>
    );
  };

  const expandableRowColumn = isTableWithExpandableRows
    ? 'var(--expandable-cell-width)'
    : '';
  const isAllColumnWidthsDefinedWithNumbers = !flattenColumnConfigs.some(
    c => typeof c.width !== 'number'
  );
  const otherColumns = flattenColumnConfigs.map(c => {
    if (typeof c.width === 'number') {
      return isAllColumnWidthsDefinedWithNumbers
        ? `minmax(${c.width}px, ${c.width}fr)`
        : `${c.width}px`;
    }
    return c.width ?? 'auto';
  });
  const renderItemActionsColumn = renderItemActions
    ? 'calc(var(--size-24) + var(--base-cell-padding) * 2)'
    : '';

  const { useScrollMeasureRef, renderCustomScrollWrapper } =
    useCustomScrollWrapper({
      shouldHideScrollBarsForNoOverflow,
      withPanel: theme !== TableThemes.tertiary,
    });

  let expandableRowsRendered = 0;

  const asyncListState = getAsyncListState<T, SkeletonItemsCount>({
    items,
    skeletonItemsCount,
    isLoading: asyncListProps?.isLoading,
  });

  const {
    isSkeletonLoading,
    loaderElement,

    noItemsMessageElement,

    isItemsNotCreated,

    itemsToRender,
  } = useAsyncList<T, HTMLTableSectionElement, SkeletonItemsCount>({
    asyncListState,
    renderNoItemsMessage: (message, currentIsItemsNotCreated) =>
      shouldNoItemsMessageHideTable && currentIsItemsNotCreated ? (
        message
      ) : (
        <tr className={styles.row}>
          <td
            {...{
              className: styles.cell,
              colSpan: columnsCount,
              style: {
                gridColumn: getSpanValue(columnsCount),
              },
            }}
          >
            <Typography
              className="col-span-full"
              variant={cellTypographyVariant}
            >
              {message}
            </Typography>
          </td>
        </tr>
      ),
    ...asyncListProps,
    noItemsMessage: shouldNoItemsMessageHideTable ? (
      noItemsMessage
    ) : (
      <DataBlockedMessage
        className={styles.blockedMessage}
        message={noItemsMessage}
      />
    ),
    noSearchItemsMessage: (
      <DataBlockedMessage
        {...{
          iconVariant: IconVariants.search,
          className: styles.blockedMessage,
          message: noSearchItemsMessage,
          description: noSearchItemsDescription,
        }}
      />
    ),
    renderLoader: sentryRef => (
      <tr className={styles.row}>
        <td
          {...{
            className: styles.cell,
            colSpan: columnsCount,
            style: {
              gridColumn: getSpanValue(columnsCount),
            },
          }}
        >
          <Typography className="col-span-full" variant={cellTypographyVariant}>
            <DataBlockedMessage
              {...{
                className: styles.blockedMessage,
                isLoading: true,
                loaderRef: sentryRef,
                message: 'Загружаем данные таблицы',
              }}
            />
          </Typography>
        </td>
      </tr>
    ),
  });

  if (shouldNoItemsMessageHideTable && isItemsNotCreated) {
    return noItemsMessageElement;
  }

  const asyncListElement = (
    <Skeleton withDelay isLoading={isSkeletonLoading}>
      <tbody
        {...{
          className: styles.tbody,

          style: {
            // We need to have explicit grid to allow negative grid line numbers for hidden data caption
            // https://www.w3.org/TR/css3-grid-layout/#explicit-grids
            gridTemplateRows: `repeat(${items.length + expandableRowsCount}, auto)`,
          },
        }}
      >
        {noItemsMessageElement}

        {itemsToRender.map((listItem, rowItemIndex, rowsArray) => {
          if (rowItemIndex === 0) {
            expandableRowsRendered = 0;
          }
          const isExpandableRow =
            isTableWithExpandableRows && hasExpandableRowContent(listItem);

          const rowElement = (
            <TableRow<T, CellData, SkeletonItemsCount>
              key={getItemKey(listItem, rowItemIndex)}
              {...{
                rowItem: listItem,
                rowItemIndex,
                rowsArray,
                gridRow: rowItemIndex + 1 + expandableRowsRendered,

                isTableWithExpandableRows,
                isExpandableRow,

                flattenColumnConfigs,

                cellTypographyVariant,

                tableProps: props,
              }}
            />
          );

          if (isExpandableRow) {
            expandableRowsRendered += 1;
          }

          return rowElement;
        })}

        {loaderElement}

        {isLargeDataHidden && shouldHideLargeDataRows && (
          <HiddenDataCaption
            {...{
              isRows: true,
              isColumnsHidden: shouldHideLargeDataColumns,
              itemsCount: items.length,
              hiddenItemsCount: hiddenRowsCount,
              onShowAll: () => setIsLargeDataHiddenState(false),
            }}
          />
        )}
      </tbody>
    </Skeleton>
  );

  const tableElement = (
    <table
      {...{
        ref: mergeRefs(useScrollMeasureRef, ref),
        className: clsx(
          styles.root,
          className,
          withStickyHeader && styles.withStickyHeader,
          theme !== TableThemes.tertiary && styles.withRoundedPanelCorners,
          styles[theme],
          withBorder && styles.withBorder
        ),
        style: {
          gridTemplateColumns: [
            expandableRowColumn,
            ...otherColumns,
            renderItemActionsColumn,
          ].join(' '),
        },
      }}
    >
      {isLargeDataHidden && shouldHideLargeDataColumns && (
        <HiddenDataCaption
          {...{
            isRows: false,
            hiddenItemsCount: hiddenColumnsCount,
            onShowAll: () => setIsLargeDataHiddenState(false),
          }}
        />
      )}
      <Skeleton withDelay isLoading={isSkeletonLoading}>
        <thead
          {...{
            className: styles.thead,
            ['data-is-sticky-thead']: withStickyHeader,
          }}
        >
          {!!printableTitle && (
            <tr role="row" className={clsx(styles.headerRow, 'only-for-print')}>
              <th
                {...{
                  role: 'cell',

                  style: {
                    gridRow: getSpanValue(columnsCount),
                  },
                }}
              >
                <Typography variant={TypographyVariants.heading2}>
                  {printableTitle}
                </Typography>
              </th>
            </tr>
          )}
          <tr className={styles.headerRow}>
            {isTableWithExpandableRows && (
              <th
                {...{
                  className: clsx(styles.headerCell, styles.expandableCell),
                  rowSpan: nestedHeaderRowSpan,
                  style: {
                    gridRow: getSpanValue(nestedHeaderRowSpan),
                  },
                }}
              />
            )}
            {flattenColumnConfigs.map(config =>
              renderHeaderColumn(config, true)
            )}
            {renderItemActions && (
              <th
                key={ITEM_ACTIONS_COLUMN_KEY}
                {...{
                  role: 'cell',
                  className: clsx(styles.headerCell, styles.itemActionCell),
                  rowSpan: nestedHeaderRowSpan,
                  style: {
                    gridRow: getSpanValue(nestedHeaderRowSpan),
                  },
                }}
              />
            )}
          </tr>
          {hasNestedColumnsLayout && (
            <tr className={styles.secondHeaderRow}>
              {flattenColumnConfigs.map(config =>
                renderHeaderColumn(config, false)
              )}
            </tr>
          )}
        </thead>
      </Skeleton>
      {asyncListElement}
    </table>
  );

  const tableWithScrollElement = withCustomScroll
    ? renderCustomScrollWrapper({
        scrollBarKey: columnConfigs.length,
        contentClassName: clsx(
          theme !== TableThemes.tertiary && 'shadow-border'
        ),
        children: tableElement,
      })
    : tableElement;

  return (
    <>
      {!isItemsNotCreated && filtersElement}
      {tableWithScrollElement}
    </>
  );
};

export * from './types';
export * from './constants';

// Workaround for typing generic HOC
type RenderTable = <
  T extends object,
  CellData = undefined,
  SkeletonItemsCount extends number | undefined = number,
>(
  props: TableProps<T, CellData, SkeletonItemsCount>
) => React.ReactElement;

export const Table = React.forwardRef<HTMLTableElement, TableProps<any, any>>(
  TableInternal
) as RenderTable;
