import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { InseminationSchemeFragmentDoc } from '../fragments/inseminationScheme.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateInseminationSchemeMutationVariables = Types.Exact<{
  input: Types.CreateInseminationSchemeInput;
}>;


export type CreateInseminationSchemeMutation = { __typename?: 'Mutation', createInseminationScheme: { __typename: 'InseminationScheme', id: string, name: string, code: string, isDefault: boolean } };


export const CreateInseminationSchemeDocument = gql`
    mutation createInseminationScheme($input: CreateInseminationSchemeInput!) {
  createInseminationScheme(input: $input) {
    ...InseminationScheme
  }
}
    ${InseminationSchemeFragmentDoc}`;
export type CreateInseminationSchemeMutationFn = Apollo.MutationFunction<CreateInseminationSchemeMutation, CreateInseminationSchemeMutationVariables>;

/**
 * __useCreateInseminationSchemeMutation__
 *
 * To run a mutation, you first call `useCreateInseminationSchemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInseminationSchemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInseminationSchemeMutation, { data, loading, error }] = useCreateInseminationSchemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInseminationSchemeMutation(baseOptions?: Apollo.MutationHookOptions<CreateInseminationSchemeMutation, CreateInseminationSchemeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInseminationSchemeMutation, CreateInseminationSchemeMutationVariables>(CreateInseminationSchemeDocument, options);
      }
export type CreateInseminationSchemeMutationHookResult = ReturnType<typeof useCreateInseminationSchemeMutation>;
export type CreateInseminationSchemeMutationResult = Apollo.MutationResult<CreateInseminationSchemeMutation>;
export type CreateInseminationSchemeMutationOptions = Apollo.BaseMutationOptions<CreateInseminationSchemeMutation, CreateInseminationSchemeMutationVariables>;