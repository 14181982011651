import { useEffect } from 'react';

import {
  createFileRoute,
  useNavigate,
  useRouterState,
} from '@tanstack/react-router';
import clsx from 'clsx';
import R from 'ramda';

import { AsyncList, getAsyncListState } from '~/shared/components/AsyncList';
import { Button, ButtonProps } from '~/shared/components/Button';
import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';
import { IconVariants } from '~/shared/components/Icon';
import { Input, InputThemes, InputVariants } from '~/shared/components/Input';
import { SelectThemes } from '~/shared/components/Select';
import { useDebouncedSearch } from '~/shared/hooks/useDebouncedSearch';
import {
  ExtractSearchParamsFromRoute,
  useSearchParamsState,
} from '~/shared/hooks/useSearchParamsState';

import {
  SearchableSearchParams,
  WithSearchParamsValidation,
} from '~/services/navigation';

import {
  useCustomReportsPaginatedQuery,
  useEditCustomReportModal,
} from '~/entities/customReports';
import { CustomReportFragment } from '~/entities/customReports/gql/fragments/customReport.graphql';
import { RoleAsyncSelect } from '~/entities/roles';

import { useCustomReportSettingsModal } from '~/features/customReportLaunch';
import { PageHeader, TileSizeSelector, useTileSize } from '~/features/layouts';

import layoutStyles from '~/styles/modules/layout.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

import { CustomReportTile } from './components';

const CUSTOM_REPORT_CARDS_DEFAULT_COUNT = 4;

interface CustomReportsSearchParams extends SearchableSearchParams {
  roleIDs: string[];
}

export const Route = createFileRoute(
  '/$companyId/_layout/user/analytics/custom-reports/'
)({
  wrapInSuspense: true,
  component: CustomReportsPage,
  validateSearch: ({
    roleIDs,
    search,
  }: WithSearchParamsValidation<CustomReportsSearchParams>) => ({
    roleIDs: Array.isArray(roleIDs) ? roleIDs : [],
    search: search ?? '',
  }),
});

function CustomReportsPage() {
  const navigate = useNavigate();

  const { open: openEditCustomReportModal } = useEditCustomReportModal();

  const { open: openCustomReportSettingsModal } =
    useCustomReportSettingsModal();

  const {
    search: debouncedSearch,
    setSearch: setDebouncedSearch,
    roleIDs,
    setRoleIDs,
  } = useSearchParamsState<ExtractSearchParamsFromRoute<typeof Route>>();

  const { search, setSearch, isSearchActive } = useDebouncedSearch({
    defaultSearch: debouncedSearch,
    onDebouncedSearchChange: setDebouncedSearch,
  });

  // Open a modal with custom report, if we have a hash param in the url
  const customReportIdForModal = useRouterState().location.hash;
  useEffect(() => {
    if (!customReportIdForModal) return;

    navigate({
      from: Route.fullPath,
      to: Route.fullPath,
      hash: '',
      search: R.identity,
      replace: true,
      resetScroll: false,
    });
    openCustomReportSettingsModal({ customReportId: customReportIdForModal });
  }, [customReportIdForModal]);

  const queryVariables = {
    roleIDs,
    search: debouncedSearch,
  };

  const { items: customReportItems, ...asyncProps } =
    useCustomReportsPaginatedQuery({
      variables: queryVariables,
    });

  const addButtonProps = {
    iconVariant: IconVariants.plus,
    children: 'Создать отчёт',
    onPress: () =>
      openEditCustomReportModal({
        onSave: newCustomReport => {
          if (newCustomReport) {
            openCustomReportSettingsModal({
              customReportId: newCustomReport.id,
            });
          }
        },
        queryVariables,
      }),
  } satisfies ButtonProps;

  const { tileSize, tilesGridClassName } = useTileSize(Route.fullPath);

  const filtersElement = (
    <div
      className={clsx(
        'flex items-end gap-16 mb-24',
        layoutStyles.limitedContainer
      )}
    >
      <RoleAsyncSelect
        {...{
          isMulti: true,
          label: 'Пользователь отчёта',
          className: 'default-control',
          name: 'roles',
          rawValue: roleIDs,
          theme: SelectThemes.light,
          onValueChange: newRoleIds => setRoleIDs(newRoleIds.map(R.prop('id'))),
          isUsedAsFilter: true,
        }}
      />
      <TileSizeSelector className="ml-a" pageName={Route.fullPath} />
      <Input
        {...{
          name: 'search',
          placeholder: 'Поиск по названию',
          value: search,
          onValueChange: setSearch,
          className: 'default-control',
          variant: InputVariants.search,
          theme: InputThemes.light,
        }}
      />
    </div>
  );

  const asyncListState = getAsyncListState({
    items: customReportItems,
    skeletonItemsCount: CUSTOM_REPORT_CARDS_DEFAULT_COUNT,
    isSearchActive: isSearchActive || !R.isEmpty(roleIDs),
    isLoading: asyncProps.isLoading,
  });

  return (
    <>
      <PageHeader
        {...{
          title: 'Отчёты',
          className: layoutStyles.limitedContainer,
          rightContent: !asyncListState.isItemsNotCreated && (
            <Button key="addCustomReport" {...addButtonProps} />
          ),
        }}
      />
      <AsyncList<CustomReportFragment>
        {...{
          asyncListState,
          filtersElement,
          renderItemsWrapper: items => (
            <div
              className={clsx(
                layoutStyles.limitedContainer,
                'container-inline-size'
              )}
            >
              <div className={tilesGridClassName}>{items}</div>
            </div>
          ),
          renderNoItemsMessage: noItemsMessage => (
            <div
              className={clsx(
                layoutStyles.fillLeftoverHeightContainer,
                layoutStyles.limitedContainer,
                panelStyles.panel,
                'p-24 grid place-items-center'
              )}
            >
              {noItemsMessage}
            </div>
          ),
          noItemsMessage: (
            <DataBlockedMessage
              {...{
                isLarge: true,
                className: 'p-24',
                message: 'Отчёты пока не созданы',
                buttonProps: addButtonProps,
              }}
            />
          ),
          noSearchItemsMessage: (
            <DataBlockedMessage
              {...{
                isLarge: true,
                className: 'p-24',
                message: 'Отчёты не найдены',
                description:
                  'По вашему запросу нет подходящих отчётов. Используйте другое название',
              }}
            />
          ),
          renderItem: customReport => (
            <CustomReportTile
              key={customReport.id}
              {...{
                customReport,
                queryVariables,
                size: tileSize,
              }}
            />
          ),
          ...asyncProps,
        }}
      />
    </>
  );
}
