import React, { isValidElement, ReactNode } from 'react';

import { MDASH } from '../constants';

/**
 * Function to render value, if condition is true, otherwise renders &mdash; symbol
 */
export const renderOrMdash = <T extends ReactNode>(
  content: T,
  condition = !!content
) => {
  if (condition) {
    return content;
  }
  return MDASH;
};

/**
 * Function to render an array of elements split by a separator
 */
export const joinJsxArray = <T extends ReactNode>(
  array: T[],
  delimiter: ReactNode = ', '
) => {
  return array
    .filter(Boolean)
    .reduce<(T | React.ReactElement)[]>((acc, item, index, currentArray) => {
      const newArray = [...acc, item];
      if (index !== currentArray.length - 1) {
        return [
          ...newArray,
          <span
            key={`${isValidElement(item) ? item.key : item?.toString()}_delimiter`}
            style={{ whiteSpace: 'pre' }}
          >
            {delimiter}
          </span>,
        ];
      }
      return newArray;
    }, []);
};

/**
 * Helper to conditionally wrap an element in a wrapperElement.
 * @param element - element to wrap
 * @param shouldWrap - if true, wraps the element
 * @param wrapperElement - should be an element with a single leaf child, that is used to insert element as a child
 *
 * @returns if shouldWrap is true, element wrapped in a wrapperElement, just element otherwise
 */
export const wrapJsxIf = (
  element: ReactNode,
  shouldWrap: boolean,
  wrapperElement: JSX.Element
): ReactNode => {
  if (!shouldWrap) {
    return element;
  }

  if (!wrapperElement.props.children) {
    return React.cloneElement(wrapperElement, wrapperElement.props, element);
  }

  return React.cloneElement(
    wrapperElement,
    wrapperElement.props,
    Array.isArray(wrapperElement.props.children)
      ? React.Children.map(wrapperElement.props.children, child => {
          if (React.isValidElement(child)) {
            return wrapJsxIf(element, shouldWrap, child);
          }
          return child;
        })
      : wrapJsxIf(element, shouldWrap, wrapperElement.props.children)
  );
};
