import React from 'react';

import clsx from 'clsx';
import R from 'ramda';

import {
  FILLER_COLUMN_CONFIG,
  Table,
  TableColumnConfig,
  TableThemes,
} from '~/shared/components/Table';
import { TextLink } from '~/shared/components/TextLink';

import { getCowIdentifier } from '~/entities/cows';

import { CutCodeFragment } from '../../gql/fragments/cutCode.graphql';
import {
  CutCodesQueryVariables,
  useCutCodesQuery,
} from '../../gql/queries/cutCodes.graphql';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Query variables for the cut codes query
   */
  queryVariables: CutCodesQueryVariables;
  /**
   * Number of sorting gates to display
   */
  sortingGatesCount: number;
}

const IDENTIFIER_COLUMN_WIDTH_PX = 164;
const GATE_COLUMN_WIDTH_PX = 108;

export const CutCodesTable: React.FC<Props> = ({
  className,
  queryVariables,
  sortingGatesCount,
}) => {
  const { data: cutCodesData, loading: isLoading } = useCutCodesQuery({
    variables: queryVariables,
  });
  const cutCodes = cutCodesData?.cutCodes.nodes;

  const columnConfigs: TableColumnConfig<CutCodeFragment, undefined>[] = [
    {
      title: 'Номер животного',
      key: 'identifier',
      renderCellContent: ({ cow }) => (
        <TextLink
          to="/$companyId/user/cows/$cowId"
          params={prev => ({ ...prev, cowId: cow.id })}
        >
          {getCowIdentifier(cow)}
        </TextLink>
      ),
      width: IDENTIFIER_COLUMN_WIDTH_PX,
    },
    ...R.range(1, sortingGatesCount + 1).map<
      TableColumnConfig<CutCodeFragment, undefined>
    >(gateNumber => ({
      key: gateNumber,
      cellClassName: 'text-right',
      title: `Сорт. ворота ${gateNumber}`,
      renderCellContent: ({ sortingGates }) =>
        sortingGates.find(gate => gate.number === gateNumber)?.value ?? '',
      width: GATE_COLUMN_WIDTH_PX,
    })),
    FILLER_COLUMN_CONFIG,
  ];

  return (
    <Table<CutCodeFragment, undefined, undefined>
      {...{
        theme: TableThemes.smallSecondary,
        className: clsx(className, 'col-span-full'),
        items: cutCodes,
        withBorder: true,
        withCustomScroll: false,
        getItemKey: ({ cow }) => cow.id,
        columnConfigs,
        noItemsMessage: 'Нет данных для отображения',
        isLoading,
      }}
    />
  );
};
