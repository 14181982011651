import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';

import { RunType, ViewKindEnum } from '@graphql-types';
import clsx from 'clsx';
import dayjs from 'dayjs';
import R from 'ramda';

import { Input, InputThemes, InputVariants } from '~/shared/components/Input';
import { Select, SelectThemes } from '~/shared/components/Select';
import { AppLocalStorageKeys } from '~/shared/constants';
import { useDebouncedSearch } from '~/shared/hooks/useDebouncedSearch';
import { useLocalStorage } from '~/shared/hooks/useLocalStorage';
import { useSearchParamsState } from '~/shared/hooks/useSearchParamsState';

import {
  DateSwitcher,
  DateSwitcherThemes,
  formatDateForBackend,
} from '~/services/dateTime';

import { RoleAsyncSelect } from '~/entities/roles';

import { TileSizeSelector } from '~/features/layouts';

import { BlueprintsSearchParams } from '~/widgets/blueprintsList';

import layoutStyles from '~/styles/modules/layout.module.scss';

interface BlueprintRunDates {
  selectedDate: string;
  dateOfSelection: string;
}

const VIEW_KIND_SELECT_ITEMS = [
  {
    id: null,
    name: 'Все типы команд',
  },
  {
    id: ViewKindEnum.Table,
    name: 'Список',
  },
  {
    id: ViewKindEnum.Action,
    name: 'Ввод данных',
  },
];

/**
 * Hook for using blueprint filtering fields
 */
export const useBlueprintFilters = (runType?: RunType) => {
  const {
    roleIds,
    setRoleIds,
    viewKind,
    setViewKind,
    search: debouncedSearch,
    setSearch: setDebouncedSearch,
  } = useSearchParamsState<BlueprintsSearchParams>();

  const { search, setSearch, isSearchActive } = useDebouncedSearch({
    defaultSearch: debouncedSearch,
    onDebouncedSearchChange: setDebouncedSearch,
  });

  const today = formatDateForBackend(dayjs().startOf('day'));

  const defaultBlueprintDates = {
    selectedDate: today,
    dateOfSelection: today,
  };

  const [blueprintRunDates, setBlueprintRunDates] =
    useLocalStorage<BlueprintRunDates>(
      AppLocalStorageKeys.blueprintRunDates,
      defaultBlueprintDates
    );

  const setBlueprintsDate: Dispatch<SetStateAction<string>> = useCallback(
    dateOrGetDate => {
      const newDate =
        dateOrGetDate instanceof Function
          ? dateOrGetDate(blueprintRunDates.selectedDate)
          : dateOrGetDate;

      setBlueprintRunDates({
        selectedDate: newDate,
        dateOfSelection: today,
      });
    },
    [blueprintRunDates]
  );

  // Reset selected blueprints date, if current date is different from the date blueprints date was set
  useEffect(() => {
    if (dayjs(blueprintRunDates.dateOfSelection).diff(dayjs(), 'days') === 0) {
      return;
    }
    setBlueprintRunDates(defaultBlueprintDates);
  }, [blueprintRunDates.dateOfSelection]);

  const blueprintsDate = blueprintRunDates.selectedDate;

  const filtersElement = (
    <div
      className={clsx(
        'mb-24 flex items-end gap-16',
        layoutStyles.limitedContainer
      )}
    >
      <DateSwitcher
        {...{
          label: 'Дата составления списков',
          labelProps: {
            tooltip: 'Списки будут составляться на выбранную дату',
          },
          name: 'blueprintsDate',
          className: 'default-control',
          isHighlighted: !dayjs().isSame(blueprintsDate, 'day'),
          value: blueprintsDate,
          theme: DateSwitcherThemes.light,
          onValueChange: setBlueprintsDate,
        }}
      />
      <RoleAsyncSelect
        {...{
          name: 'roles',
          isMulti: true,
          rawValue: roleIds,
          className: 'default-control',
          theme: SelectThemes.light,
          onValueChange: newRoleIds => setRoleIds(newRoleIds.map(R.prop('id'))),
          isUsedAsFilter: true,
        }}
      />
      <Select<(typeof VIEW_KIND_SELECT_ITEMS)[number]>
        {...{
          name: 'viewKind',
          className: 'default-control',
          label: 'Тип команды',
          items: VIEW_KIND_SELECT_ITEMS,
          onValueChange: newValue => setViewKind(newValue?.id ?? undefined),
          rawValue: viewKind ?? null,
          theme: SelectThemes.light,
        }}
      />
      {runType && <TileSizeSelector className="ml-a" pageName={runType} />}
      <Input
        {...{
          name: 'search',
          className: 'default-control',
          placeholder: 'Поиск по названию команды',
          value: search,
          onValueChange: setSearch,
          variant: InputVariants.search,
          theme: InputThemes.light,
        }}
      />
    </div>
  );

  return {
    isSearchActive: isSearchActive || !R.isEmpty(roleIds),
    roleIds,
    setRoleIds,
    viewKind,
    setViewKind,
    search,
    debouncedSearch,
    setSearch,
    blueprintsDate,
    setBlueprintsDate,

    filtersElement,
  };
};
