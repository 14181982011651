import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateOperationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.UpdateOperationInput;
}>;


export type UpdateOperationMutation = { __typename?: 'Mutation', updateOperation?: any | null };


export const UpdateOperationDocument = gql`
    mutation updateOperation($id: ID!, $input: UpdateOperationInput!) {
  updateOperation(id: $id, input: $input)
}
    `;
export type UpdateOperationMutationFn = Apollo.MutationFunction<UpdateOperationMutation, UpdateOperationMutationVariables>;

/**
 * __useUpdateOperationMutation__
 *
 * To run a mutation, you first call `useUpdateOperationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOperationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOperationMutation, { data, loading, error }] = useUpdateOperationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOperationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOperationMutation, UpdateOperationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOperationMutation, UpdateOperationMutationVariables>(UpdateOperationDocument, options);
      }
export type UpdateOperationMutationHookResult = ReturnType<typeof useUpdateOperationMutation>;
export type UpdateOperationMutationResult = Apollo.MutationResult<UpdateOperationMutation>;
export type UpdateOperationMutationOptions = Apollo.BaseMutationOptions<UpdateOperationMutation, UpdateOperationMutationVariables>;