import React from 'react';

import { RunType } from '@graphql-types';
import clsx from 'clsx';

import { Button, ButtonVariants } from '~/shared/components/Button';
import { IconVariants } from '~/shared/components/Icon';

import { useArkaNavigation, useLayoutState } from '~/services/navigation';

import { BasePageHeaderProps, PageHeader } from '~/features/layouts';

import layoutStyles from '~/styles/modules/layout.module.scss';

interface Props extends BasePageHeaderProps {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Title to display
   */
  title: string;
  /**
   * Blueprints run type
   */
  runType: RunType;
}

export const BlueprintsPageHeader: React.FC<Props> = ({
  className,
  title,
  runType,
  ...pageHeaderProps
}) => {
  const {
    navigate,
    urlCompanyId,
    isIntegratorInSelectedCompany: canEdit,
  } = useArkaNavigation();

  useLayoutState({
    headerRightContent: canEdit && (
      <Button
        {...{
          className: 'ml-a',
          onPress: () => {
            navigate({
              to: '/$companyId/integrator/blueprints/edit/master',
              params: { companyId: urlCompanyId },
            });
          },
          iconVariant: IconVariants.edit,
          variant: ButtonVariants.secondary,
        }}
      >
        Редактировать общие поля
      </Button>
    ),
    layoutPrimaryButtonProps: canEdit && {
      iconVariant: IconVariants.plus,
      onPress: () => {
        navigate({
          to: '/$companyId/integrator/blueprints/$blueprintId',
          params: {
            blueprintId: 'new',
            companyId: urlCompanyId,
          },
          search: {
            runType,
          },
        });
      },
      children: 'Создать карточку',
    },
    dependencies: [canEdit],
  });

  return (
    <PageHeader
      {...{
        className: clsx(className, layoutStyles.limitedContainer),
        title,
        ...pageHeaderProps,
      }}
    />
  );
};
