import { makeUpdateFragment } from '~/services/gql';

import {
  InseminationSchemeFragment,
  InseminationSchemeFragmentDoc,
} from './gql/fragments/inseminationScheme.graphql';

/**
 * Update InseminationSchemeFragment in the cache
 */
export const updateInseminationSchemeFragment =
  makeUpdateFragment<InseminationSchemeFragment>({
    typeName: 'InseminationScheme',
    fragment: InseminationSchemeFragmentDoc,
  });
