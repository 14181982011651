import dayjs from 'dayjs';

import { formatDateForBackend } from '~/services/dateTime';

import { CowsCopyKeyFragment } from '../../gql/fragments/CowsCopyKey.graphql';
import { CowsCopyKeyPayload, MappingType } from './types';

/**
 * Filter helper for preparing identifiers backend payload
 */
export const filterIdentifiersByMappingType = (
  items: {
    mappingType: MappingType;
    originID: string;
    targetID: string | null;
  }[]
) =>
  items.reduce(
    (acc, { mappingType, originID, targetID }) => {
      if (mappingType === MappingType.create) {
        acc.push({ originID, targetID: null });
      }
      if (mappingType === MappingType.equally) {
        acc.push({ originID, targetID });
      }
      return acc;
    },
    [] as { originID: string; targetID: string | null }[]
  );

/**
 * Payload builder for sending info from forms to backend
 */
export const makeCowsCopyKeyPayload = (
  cowsCopyKey: CowsCopyKeyFragment
): CowsCopyKeyPayload => ({
  key: cowsCopyKey.key,
  arriveDate: cowsCopyKey.arriveDate ?? formatDateForBackend(dayjs(), true),
  identifierMappings: cowsCopyKey.cows.map(({ identifier }) => ({
    originIdentifier: identifier,
    targetIdentifier: identifier,
  })),
  operationMappings: cowsCopyKey.operations.map(({ id }) => ({
    originID: id,
    targetID: null,
    mappingType: MappingType.equally,
  })),
  diseaseMappings: cowsCopyKey.diseases.map(({ id }) => ({
    originID: id,
    targetID: null,
    mappingType: MappingType.equally,
  })),
  inseminationBullMappings: cowsCopyKey.inseminationBulls.map(({ id }) => ({
    originID: id,
    targetID: null,
    mappingType: MappingType.equally,
  })),
  penGroupID: '',
  farmID: '',
});
