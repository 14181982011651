import { createFileRoute } from '@tanstack/react-router';
import clsx from 'clsx';

import { getAsyncListState } from '~/shared/components/AsyncList';
import { Button, ButtonProps } from '~/shared/components/Button';
import { ContextMenuButton } from '~/shared/components/ContextMenuButton';
import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';
import { IconVariants } from '~/shared/components/Icon';
import { Input, InputVariants } from '~/shared/components/Input';
import { MenuItemVariants } from '~/shared/components/Menu';
import { isSkeletonPlaceholder } from '~/shared/components/Skeleton';
import {
  Table,
  TableColumnConfig,
  TableThemes,
} from '~/shared/components/Table';
import { wrapJsxIf } from '~/shared/helpers/render';
import { useDebouncedSearch } from '~/shared/hooks/useDebouncedSearch';

import { useBullsDetailedPaginatedQuery } from '~/entities/bulls';
import { BullDetailedFragment } from '~/entities/bulls/gql/fragments/bullDetailed.graphql';

import { PageHeader } from '~/features/layouts';

import layoutStyles from '~/styles/modules/layout.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

import { useDeleteBullModal, useEditBullModal } from './modals';

const BULL_ROWS_SKELETON_COUNT = 8;

const BASE_COLUMN_WIDTH_PX = 204;
const BREED_COLUMN_WIDTH_PX = 120;

export const Route = createFileRoute('/$companyId/_layout/user/herd/bulls/')({
  wrapInSuspense: true,
  component: BullsPage,
});

function BullsPage() {
  const { open: openEditBullModal } = useEditBullModal();
  const { open: openDeleteBullModal } = useDeleteBullModal();

  const { search, setSearch, debouncedSearch, isSearchActive } =
    useDebouncedSearch();

  const queryVariables = {
    search: debouncedSearch,
  };

  const { items, ...asyncProps } = useBullsDetailedPaginatedQuery({
    variables: queryVariables,
  });

  const addButtonProps = {
    iconVariant: IconVariants.plus,
    children: 'Добавить быка',
    onPress: () => openEditBullModal(),
  } satisfies ButtonProps;

  const filtersElement = (
    <div className={clsx('flex gap-16 mb-24', layoutStyles.limitedContainer)}>
      <Input
        {...{
          name: 'search',
          placeholder: 'Поиск',
          value: search,
          onValueChange: setSearch,
          className: 'default-control',
          variant: InputVariants.search,
        }}
      />
    </div>
  );

  const columnConfigs: TableColumnConfig<BullDetailedFragment>[] = [
    {
      title: 'Рег. номер',
      key: 'registrationNumber',
      itemField: 'registrationNumber',
      width: BASE_COLUMN_WIDTH_PX,
    },
    {
      title: 'Кличка',
      key: 'name',
      itemField: 'name',
      width: BASE_COLUMN_WIDTH_PX,
    },
    {
      title: 'Межд. номер',
      key: 'usdaNumber',
      itemField: 'usdaNumber',
      width: BASE_COLUMN_WIDTH_PX,
    },
    {
      title: 'Порода',
      key: 'breed',
      itemField: 'breed',
      width: BREED_COLUMN_WIDTH_PX,
    },
    {
      title: 'Комментарий',
      key: 'comment',
      itemField: 'comment',
      width: '1fr',
    },
  ];

  const asyncListState = getAsyncListState({
    items,
    skeletonItemsCount: BULL_ROWS_SKELETON_COUNT,
    isSearchActive,
    isLoading: asyncProps.isLoading,
  });

  const tableElement = (
    <Table<BullDetailedFragment>
      {...{
        asyncListState,
        theme: TableThemes.largeSecondary,
        className: 'min-w-full w-min',
        items,
        columnConfigs,
        filtersElement,
        shouldNoItemsMessageHideTable: true,
        noItemsMessage: (
          <div
            className={clsx(
              layoutStyles.fillLeftoverHeightContainer,
              layoutStyles.limitedContainer,
              panelStyles.panel,
              'p-24 grid place-items-center'
            )}
          >
            <DataBlockedMessage
              {...{
                isLarge: true,
                className: 'p-24',
                message: 'Быки пока не добавлены',
                buttonProps: addButtonProps,
              }}
            />
          </div>
        ),
        noSearchItemsMessage: 'Быки не найдены',
        noSearchItemsDescription:
          'По вашему запросу нет подходящих быков. Используйте другой рег. номер или кличку',
        isSearchActive,
        renderItemActions: bull =>
          !isSkeletonPlaceholder(bull) && (
            <ContextMenuButton
              {...{
                tooltip: 'Действия с быком',
                items: [
                  {
                    content: 'Редактировать',
                    onPress: () => {
                      openEditBullModal({
                        bull,
                      });
                    },
                  },
                  {
                    variant: MenuItemVariants.destructive,
                    content: 'Удалить',
                    onPress: () => {
                      openDeleteBullModal({
                        bull,
                        queryVariables,
                      });
                    },
                  },
                ],
              }}
            />
          ),
        ...asyncProps,
      }}
    />
  );

  return (
    <>
      <PageHeader
        {...{
          className: layoutStyles.limitedContainer,
          title: 'Генетика',
          rightContent: !asyncListState.isItemsNotCreated && (
            <Button key="addBull" {...addButtonProps} />
          ),
        }}
      />

      {wrapJsxIf(
        tableElement,
        !asyncListState.isItemsNotCreated,
        <div
          className={clsx(
            panelStyles.largePanel,
            layoutStyles.limitedContainer,
            'p-24'
          )}
        />
      )}
    </>
  );
}
