import { LimbEnum } from '@graphql-types';

import {
  makeSelectComponentFromHook,
  makeUseEnumSelect,
} from '~/shared/components/Select';

const LIMB_DICT: Record<LimbEnum, string> = {
  [LimbEnum.Al]: 'Все',
  [LimbEnum.Lf]: 'Левая передняя',
  [LimbEnum.Lr]: 'Левая задняя',
  [LimbEnum.Rf]: 'Правая передняя',
  [LimbEnum.Rr]: 'Правая задняя',
  [LimbEnum.Bf]: '2 передние',
  [LimbEnum.Bh]: '2 задние',
  [LimbEnum.Bl]: '2 левые',
  [LimbEnum.Br]: '2 правые',
  [LimbEnum.Lx]: 'Левая передняя + Правая задняя',
  [LimbEnum.Rx]: 'Правая передняя + Левая задняя',
  [LimbEnum.Fl]: 'Все, кроме левой передней',
  [LimbEnum.Fr]: 'Все, кроме правой передней',
  [LimbEnum.Rl]: 'Все, кроме левой задней',
  [LimbEnum.Hr]: 'Все, кроме правой задней',
};

const useLimbSelect = makeUseEnumSelect(
  LimbEnum,
  enumValue => LIMB_DICT[enumValue as LimbEnum]
);

export const LimbSelect = makeSelectComponentFromHook(useLimbSelect);
