import { useEffect, useState } from 'react';

import { useApolloClient } from '@apollo/client';

import { createFileRoute } from '@tanstack/react-router';
import clsx from 'clsx';

import { AsyncList, getAsyncListState } from '~/shared/components/AsyncList';
import { Button, ButtonProps } from '~/shared/components/Button';
import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';
import { IconVariants } from '~/shared/components/Icon';
import { Input, InputThemes, InputVariants } from '~/shared/components/Input';
import { useDebouncedSearch } from '~/shared/hooks/useDebouncedSearch';

import { useMyUser } from '~/services/auth';

import {
  useCompaniesPaginatedQuery,
  useEditCompanyModal,
} from '~/entities/companies';
import { CompanyFragment } from '~/entities/companies/gql/fragments/company.graphql';

import {
  BaseLayout,
  PageHeader,
  TileSizeSelector,
  useTileSize,
} from '~/features/layouts';

import layoutStyles from '~/styles/modules/layout.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

import { CompanyTile } from './components/CompanyTile';

const COMPANY_TILES_DEFAULT_COUNT = 4;

export const Route = createFileRoute('/select-company/')({
  wrapInSuspense: true,
  component: SelectCompanyPageWrapper,
});

function SelectCompanyPage() {
  const { isIntegrator } = useMyUser();

  const { open: openEditCompanyModal } = useEditCompanyModal();

  const [isStoreCleared, setStoreCleared] = useState(false);

  // It covers the case with loader when uploading data to company and come back to this page
  const apolloClient = useApolloClient();
  useEffect(() => {
    // Reset store, so we won't have wrong cache for different selected company
    setStoreCleared(false);
    apolloClient.clearStore().finally(() => {
      setStoreCleared(true);
    });
  }, []);

  const { search, setSearch, debouncedSearch, isSearchActive } =
    useDebouncedSearch();

  const queryVariables = {
    search: debouncedSearch,
  };

  const { items: companies, ...asyncProps } = useCompaniesPaginatedQuery({
    variables: queryVariables,
    skip: !isStoreCleared,
  });

  const addCompanyButtonProps = {
    iconVariant: IconVariants.plus,
    onPress: () => openEditCompanyModal(),
    children: 'Добавить компанию',
  } satisfies ButtonProps;

  const { tileSize, tilesGridClassName } = useTileSize(Route.fullPath);

  const filtersElement = (
    <div
      className={clsx('flex items-end mb-24', layoutStyles.limitedContainer)}
    >
      <Input
        {...{
          name: 'search',
          className: 'default-control',
          placeholder: 'Поиск',
          value: search,
          onValueChange: setSearch,
          variant: InputVariants.search,
          theme: InputThemes.light,
        }}
      />
      <TileSizeSelector className="ml-a" pageName={Route.fullPath} />
    </div>
  );

  const asyncListState = getAsyncListState({
    items: companies,
    skeletonItemsCount: COMPANY_TILES_DEFAULT_COUNT,
    isSearchActive,
    isLoading: !isStoreCleared || asyncProps.isLoading,
  });

  return (
    <>
      <PageHeader
        {...{
          className: layoutStyles.limitedContainer,
          title: 'Компании',
          rightContent: isIntegrator && <Button {...addCompanyButtonProps} />,
        }}
      />
      <AsyncList<CompanyFragment>
        {...{
          asyncListState,
          filtersElement,
          renderItemsWrapper: items => (
            <div
              className={clsx(
                layoutStyles.limitedContainer,
                'container-inline-size'
              )}
            >
              <div className={tilesGridClassName}>{items}</div>
            </div>
          ),
          renderNoItemsMessage: noItemsMessage => (
            <div
              className={clsx(
                layoutStyles.fillLeftoverHeightContainer,
                layoutStyles.limitedContainer,
                panelStyles.panel,
                'p-24 grid place-items-center'
              )}
            >
              {noItemsMessage}
            </div>
          ),
          noItemsMessage: (
            <DataBlockedMessage
              {...{
                isLarge: true,
                className: 'p-24',
                message: 'Компании пока не добавлены',
                buttonProps: isIntegrator ? addCompanyButtonProps : undefined,
              }}
            />
          ),
          noSearchItemsMessage: (
            <DataBlockedMessage
              {...{
                isLarge: true,
                className: 'p-24',
                message: 'Компании не найдены',
                description:
                  'По вашему запросу нет подходящих компаний. Используйте другое название',
              }}
            />
          ),
          renderItem: company => (
            <CompanyTile
              key={company.id}
              {...{
                company,
                queryVariables,
                size: tileSize,
              }}
            />
          ),
          ...asyncProps,
        }}
      />
    </>
  );
}

function SelectCompanyPageWrapper() {
  return (
    <BaseLayout withMenu={false}>
      <SelectCompanyPage />
    </BaseLayout>
  );
}
