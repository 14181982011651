import { useLayoutEffect } from 'react';
import { InViewHookResponse } from 'react-intersection-observer';

import R from 'ramda';

import { Loader } from '~/shared/components/Loader';
import { useInfiniteScroll } from '~/shared/hooks/useInfiniteScroll';

import { UseAsyncListProps } from '../types';

const renderDefaultLoader = (sentryRef?: InViewHookResponse['ref']) => (
  <Loader className="col-span-full" ref={sentryRef} />
);

/**
 * Hook that provides the UX of an AsyncList for building custom list-like components like tables or dropdowns.
 * It is responsible for consistent loading and empty lists behaviors across the system, such as:
 *
 * 1. Infinite items scrolling, including a loader with a sentryRef to use in your component markup
 * 2. Showing different noItemsMessage nodes, while there is no items at all, or no search items
 * 3. Providing skeleton placeholders to render on initial items loading
 * 4. Showing or hiding filters, based on is search active and available items count
 */
export const useAsyncList = <
  Item extends any,
  RootElement extends HTMLElement = HTMLDivElement,
  SkeletonItemsCount extends number | undefined = number,
>({
  asyncListState: {
    isLoading,
    isFetchingMore,
    isSearchActive,
    isSkeletonLoading,
    isNoItems,
    isItemsNotCreated,
    itemsToRender,
  },

  noItemsMessage,
  noSearchItemsMessage = noItemsMessage,
  renderLoader = renderDefaultLoader,
  renderNoItemsMessage = R.identity,

  ...infiniteScrollProps
}: UseAsyncListProps<Item, SkeletonItemsCount>) => {
  const { rootRef, sentryRef } = useInfiniteScroll<RootElement>({
    isLoading,
    ...infiniteScrollProps,
  });

  const { hasMore } = infiniteScrollProps;

  // Prevent scroll sticking to the bottom border
  // and continuously calling onFetchMore, when scrolling too fast
  useLayoutEffect(() => {
    if (rootRef.current) {
      rootRef.current.scrollTop -= 1;
    }
  }, [itemsToRender.length]);

  let loaderElement;
  if (!isSkeletonLoading && isLoading && !isFetchingMore) {
    loaderElement = renderLoader?.();
  } else if (isFetchingMore || hasMore) {
    loaderElement = renderLoader?.(sentryRef);
  }

  const noItemsMessageToDisplay = isSearchActive
    ? noSearchItemsMessage
    : noItemsMessage;
  const noItemsMessageElement =
    isNoItems &&
    renderNoItemsMessage(noItemsMessageToDisplay, isItemsNotCreated);

  return {
    isSkeletonLoading,
    loaderElement,

    isNoItems,
    noItemsMessageElement,

    isItemsNotCreated,

    itemsToRender,
    rootRef,
  };
};
