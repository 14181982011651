import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type CowOperationFragment = { __typename: 'CowOperation', id: string, operation: { __typename: 'Operation', id: string, name: string, kind: Types.EventKindEnum } };

export const CowOperationFragmentDoc = gql`
    fragment CowOperation on CowOperation {
  __typename
  id
  operation {
    __typename
    id
    name
    kind
  }
}
    `;