import { createContext, ReactElement, ReactNode, useContext } from 'react';

import { ButtonPropsWithoutRef } from '~/shared/components/Button';
import { Falsy } from '~/shared/types/utility';

/**
 * Context type for a router tabs context
 */
interface LayoutContextType {
  headerRightContent?: ReactNode;
  setHeaderRightContent: (rightContent?: ReactNode) => void;
  routerTabsRightContent?: ReactElement | Falsy;
  setRouterTabsRightContent: (rightContent?: ReactElement | Falsy) => void;
  layoutPrimaryButtonProps?: Partial<ButtonPropsWithoutRef> | Falsy;
  setLayoutPrimaryButtonProps: (
    buttonProps?: Partial<ButtonPropsWithoutRef> | Falsy
  ) => void;
}

/**
 * Context for controlling upper level layout from the nested pages
 */
export const LayoutContext = createContext<LayoutContextType>({
  headerRightContent: undefined,
  setHeaderRightContent: () => {},
  routerTabsRightContent: undefined,
  setRouterTabsRightContent: () => {},
  layoutPrimaryButtonProps: undefined,
  setLayoutPrimaryButtonProps: () => {},
});

/**
 * Hook for using the router tabs context, so the nested pages can control the layout
 */
export const useLayoutContext = () => useContext(LayoutContext);
