import { getSkeletonPlaceholders } from '~/shared/components/Skeleton';

import {
  AsyncListItemToRender,
  AsyncListState,
  GetAsyncListStateProps,
} from './types';

/**
 * Calculates all special items conditions for rendering an AsyncList
 */
export const getAsyncListState = <
  Item extends any,
  SkeletonItemsCount extends number | undefined = undefined,
>({
  items,
  skeletonItemsCount,
  isFetchingMore,
  isSearchActive,
  isLoading,
}: GetAsyncListStateProps<Item, SkeletonItemsCount>): AsyncListState<
  Item,
  SkeletonItemsCount
> => {
  const isSkeletonLoading =
    !!skeletonItemsCount && !!isLoading && !isFetchingMore && !items.length;

  const itemsToRender = (
    isSkeletonLoading ? getSkeletonPlaceholders(skeletonItemsCount) : items
  ) as AsyncListItemToRender<Item, SkeletonItemsCount>[];

  const isNoItems = !itemsToRender.length && !isLoading;

  const isItemsNotCreated = isNoItems && !isSearchActive;

  return {
    isLoading: !!isLoading,
    isSearchActive: !!isSearchActive,
    isFetchingMore: !!isFetchingMore,
    isSkeletonLoading,
    isNoItems,
    isItemsNotCreated,
    itemsToRender,
  };
};
