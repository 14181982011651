import { useCallback } from 'react';

import { RunType } from '@graphql-types';
import { createFileRoute } from '@tanstack/react-router';
import R from 'ramda';

import { getAsyncListState } from '~/shared/components/AsyncList';
import {
  normalizeToArrayOrUndefined,
  normalizeToValueOrUndefined,
} from '~/shared/helpers/normalize';

import {
  makeDeleteFragmentFromQuery,
  makeDeleteQueriesByNameWithoutVariables,
} from '~/services/gql';
import { WithSearchParamsValidation } from '~/services/navigation';

import { useBlueprintsPaginatedQuery } from '~/entities/blueprints';
import { useDeleteBlueprintMutation } from '~/entities/blueprints/gql/mutations/deleteBlueprint.graphql';
import {
  BlueprintsDocument,
  BlueprintsQuery,
  BlueprintsQueryVariables,
} from '~/entities/blueprints/gql/queries/blueprints.graphql';

import {
  BLUEPRINT_TILES_DEFAULT_COUNT,
  BlueprintsPageHeader,
  BlueprintsSearchParams,
  BlueprintTilesList,
  useBlueprintFilters,
} from '~/widgets/blueprintsList';

export const Route = createFileRoute('/$companyId/_layout/user/incidents/')({
  component: IncidentsPage,
  wrapInSuspense: true,
  validateSearch: ({
    roleIds,
    viewKind,
    search,
  }: WithSearchParamsValidation<BlueprintsSearchParams>) => ({
    roleIds: Array.isArray(roleIds) ? roleIds : [],
    viewKind: viewKind ?? undefined,
    search: search ?? '',
  }),
});

const PRODUCTION_CALENDAR_RUN_TYPE = RunType.Incident;

function IncidentsPage() {
  const { roleIds, viewKind, debouncedSearch, isSearchActive, filtersElement } =
    useBlueprintFilters(PRODUCTION_CALENDAR_RUN_TYPE);

  // Load blueprints
  const queryVariables = {
    runType: RunType.Incident,
    roleIDs: normalizeToArrayOrUndefined(roleIds),
    search: normalizeToValueOrUndefined(debouncedSearch),
    viewKind: normalizeToValueOrUndefined(viewKind),
  };

  const { items: blueprintItems, ...asyncProps } = useBlueprintsPaginatedQuery({
    variables: queryVariables,
  });

  // Blueprint deletion logic
  const [deleteBlueprint] = useDeleteBlueprintMutation();

  const handleDeleteBlueprint = useCallback((blueprintId: string) => {
    deleteBlueprint({
      variables: {
        id: blueprintId,
      },
      optimisticResponse: { deleteBlueprint: null },
      update: R.juxt([
        makeDeleteFragmentFromQuery<BlueprintsQuery, BlueprintsQueryVariables>({
          typeName: 'Blueprint',
          query: BlueprintsDocument,
          variables: queryVariables,
        })(blueprintId),
        makeDeleteQueriesByNameWithoutVariables('blueprints', queryVariables),
      ]),
      refetchQueries: ['blueprints'],
    });
  }, []);

  const asyncListState = getAsyncListState({
    items: blueprintItems,
    skeletonItemsCount: BLUEPRINT_TILES_DEFAULT_COUNT,
    isSearchActive,
    isLoading: asyncProps.isLoading,
  });

  return (
    <>
      <BlueprintsPageHeader
        {...{
          title: 'Команды',
          runType: PRODUCTION_CALENDAR_RUN_TYPE,
          shouldShowLayoutPrimaryButton: !asyncListState.isItemsNotCreated,
        }}
      />

      <BlueprintTilesList
        {...{
          asyncListState,
          blueprints: blueprintItems,
          runType: PRODUCTION_CALENDAR_RUN_TYPE,
          onDelete: handleDeleteBlueprint,
          filtersElement,
          ...asyncProps,
        }}
      />
    </>
  );
}
