import { ReactElement, ReactNode, useEffect } from 'react';

import { ButtonPropsWithoutRef } from '~/shared/components/Button';
import { Falsy } from '~/shared/types/utility';

import { useLayoutContext } from '../context';

interface UseLayoutStateProps {
  /**
   * Content to render on the right part of the header.
   */
  headerRightContent?: ReactNode;
  /**
   * Content to render on the right part of the router tab header.
   */
  routerTabsRightContent?: ReactElement | Falsy;
  /**
   * Special type of rightContent for rendering a primary button,
   * that can be displayed in the header on at the center of the page
   */
  layoutPrimaryButtonProps?: Partial<ButtonPropsWithoutRef> | Falsy;
  /**
   * Used to trigger layout state rerender
   */
  dependencies?: unknown[];
}

/**
 * Hook for controlling the upper level page layout from the nested pages
 */
export const useLayoutState = ({
  headerRightContent,
  routerTabsRightContent,
  layoutPrimaryButtonProps,
  dependencies = [],
}: UseLayoutStateProps) => {
  const {
    setHeaderRightContent,
    setRouterTabsRightContent,
    setLayoutPrimaryButtonProps,
  } = useLayoutContext();

  useEffect(() => {
    setHeaderRightContent(headerRightContent);
    setRouterTabsRightContent(routerTabsRightContent);
    setLayoutPrimaryButtonProps(layoutPrimaryButtonProps);

    return () => {
      setHeaderRightContent(undefined);
      setRouterTabsRightContent(undefined);
      setLayoutPrimaryButtonProps(undefined);
    };
  }, [...dependencies]);
};
