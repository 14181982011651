import React from 'react';

import { ConceptionRateParameterEnum } from '@graphql-types';
import clsx from 'clsx';
import R from 'ramda';

import {
  FILLER_COLUMN_CONFIG,
  Table,
  TableColumnConfig,
  TableRowConfig,
} from '~/shared/components/Table';
import { TypographyVariants } from '~/shared/components/Typography';
import { formatInt } from '~/shared/helpers/number';

import { ReproductionCrCrossTableSubTableFragment } from '~/entities/reproductionCrReports/gql/fragments/reproductionCrCrossTableSubTable.graphql';

import { REPRODUCTION_CONCEPTION_RATE_PARAMETER_ENUM_DICT } from '../../../../constants';
import { formatReproductionCrValue } from '../../../../helpers';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Mode for x axis
   */
  xAxisMode: ConceptionRateParameterEnum;
  /**
   * Group by parameter
   */
  groupBy: ConceptionRateParameterEnum;
  /**
   * Cross CR data to display
   */
  tableData: ReproductionCrCrossTableSubTableFragment;
}

/**
 * Data passed from column to row to render CR table cell
 */
interface ReproductionCrReportCellData {
  columnIndex: number;
}

/**
 * Type for custom milking report table row config
 */
type ReproductionCrReportRowConfig = TableRowConfig<
  {
    rowName: string;
    dataPoints: number[];
  },
  ReproductionCrReportCellData
>;

/**
 * Type for custom milking report table column config
 */
type ReproductionCrReportColumnConfig = TableColumnConfig<
  ReproductionCrReportRowConfig,
  undefined
>;

const TITLE_COLUMN_WIDTH_PX = 240;

export const ReproductionCrCrossTable: React.FC<Props> = ({
  className,
  xAxisMode,
  groupBy,
  tableData,
}) => {
  const shouldShowOther = !!tableData.other;

  const xAxisName = REPRODUCTION_CONCEPTION_RATE_PARAMETER_ENUM_DICT[xAxisMode];
  const groupByName = REPRODUCTION_CONCEPTION_RATE_PARAMETER_ENUM_DICT[groupBy];

  const columnConfigs: ReproductionCrReportColumnConfig[] = [
    {
      key: 'title',
      title: xAxisName,
      renderCellContent: R.prop('firstColumnContent'),
      width: TITLE_COLUMN_WIDTH_PX,
      isSticky: true,
    },
    {
      key: 'data',
      title: groupByName,
      nestedColumns: tableData.columnNames.map((columnName, columnIndex) => {
        let columnNameFormatted =
          columnIndex === tableData.columnNames.length - 1
            ? 'Итого'
            : formatReproductionCrValue(columnName);
        if (Array.isArray(columnNameFormatted)) {
          columnNameFormatted = columnNameFormatted.join('');
        }

        return {
          key: `${columnNameFormatted}__${columnIndex}`,
          title: columnNameFormatted,
          columnClassName: 'text-right',
          renderCellContent: row => row.renderCellContent?.({ columnIndex }),
        };
      }),
    },
    FILLER_COLUMN_CONFIG,
  ];

  const reportRows = [
    ...tableData.rows,
    tableData.other,
    tableData.total,
  ].filter(Boolean);

  const rowConfigs: ReproductionCrReportRowConfig[] = reportRows.map(
    (row, rowIndex) => {
      const isTotal = rowIndex === reportRows.length - 1;
      let firstColumnContent;
      if (isTotal) {
        firstColumnContent = 'Всего';
      } else if (shouldShowOther && rowIndex === reportRows.length - 2) {
        firstColumnContent = 'Прочие';
      } else {
        firstColumnContent = formatReproductionCrValue(
          tableData.rowNames[rowIndex]
        );
      }

      return {
        id: `${firstColumnContent.toString()}__${rowIndex}`,
        firstColumnContent,
        rowClassName: isTotal
          ? 'background-neutral-opaque-container-default'
          : undefined,
        rowTypographyVariant: isTotal
          ? TypographyVariants.bodySmallStrong
          : undefined,
        renderCellContent: ({ columnIndex }) => formatInt(row[columnIndex]),
      };
    }
  );

  return (
    <Table<
      ReproductionCrReportRowConfig,
      ReproductionCrReportCellData,
      undefined
    >
      {...{
        className: clsx(className, 'min-w-full w-min'),
        items: rowConfigs,
        columnConfigs,
        noItemsMessage: 'Нет данных для отображения',
        getRowClassName: R.prop('rowClassName'),
        getRowTypographyVariant: R.prop('rowTypographyVariant'),
      }}
    />
  );
};
