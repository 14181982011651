import React, { ReactNode } from 'react';

import { UseComboboxReturnValue } from 'downshift';

import { AsyncListProps, AsyncListState } from '~/shared/components/AsyncList';
import { PopoverProps } from '~/shared/components/Popover';
import { UseInfiniteScrollProps } from '~/shared/hooks/useInfiniteScroll';
import {
  BaseFieldProps,
  WithIsMultiFieldBaseProps,
} from '~/shared/types/controls';

import { ColorVariants } from '~/styles/__generated__/token-variants';

/**
 * Select can contain objects or strings as items
 */
export type SelectItem = Record<string, any>;

/**
 * Helper for creating a default select item with constrained id
 */
export interface SelectItemWithId<T> {
  id: T;
  name: string;
}

/**
 * Possible select variants
 */
export enum SelectVariants {
  full = 'full',
  withItemsList = 'withItemsList',
  compact = 'compact',
  popupSearch = 'popupSearch',
}

/**
 * Possible select display variants
 */
export enum SelectThemes {
  light = 'light',
  dark = 'dark',
  basic = 'basic',
}

/**
 * Props for select without base fields props
 */
export interface SelectBaseProps<I extends SelectItem = SelectItem> {
  /**
   * Array of items that populate the dropdown menu
   */
  items: I[];

  /**
   * Hack for blueprint cycle inputs focus to work
   * TODO remove after complete rework of blueprint value inputs
   */
  inputRef?: React.Ref<HTMLInputElement>;
  /**
   * If true, select opens, when down or up arrows are pressed (default - true)
   */
  shouldOpenOnArrows?: boolean;

  onKeyDown?: (
    e: React.KeyboardEvent<HTMLElement>,
    menuProps: { isOpen: boolean; openMenu: () => void; closeMenu: () => void }
  ) => void;

  /**
   * Select variant
   */
  variant?: SelectVariants;
  /**
   * Select display variant
   */
  theme?: SelectThemes;
  /**
   * If true, select can be cleared and undefined can be selected (for single select)
   */
  isClearable?: boolean;
  /**
   * If passed, an additional action item is rendered in the dropdown
   */
  listActionLabel?: ReactNode;
  /**
   * Called, when the additional action item is pressed
   */
  onListActionPress?: () => void;
  /**
   * If true, select is rendered in optimistic update state with a fade
   */
  isOptimistic?: boolean;
  /**
   * If passed, select is blocked from opening and showed with a loading message
   */
  loadingMessage?: ReactNode;
  /**
   * Width of the dropdown popover (default to select element width)
   */
  popoverWidth?: number;
  /**
   * Props to override default popover props like placement
   */
  popoverProps?: Partial<
    Omit<PopoverProps, 'withListNavigation' | 'listNavigationProps'>
  >;

  /**
   * Get primitive value from item to store in forms
   */
  getItemValue?: (item: I) => string;
  /**
   * Text representation is used for searching items and is displayed by default
   */
  getItemText?: (item: I | null) => string;
  /**
   * Render prop to customize selected element rendering, that can't be represented as a string
   */
  renderSelectedItem?: (item: I | null) => ReactNode;
  /**
   * Render prop to customize item rendering, that can't be represented as a string
   */
  renderItemText?: (item: I | null) => ReactNode;
  /**
   * Text description to display in menu list
   */
  getItemDescription?: (item: I | null) => string | undefined;
  /**
   * Items may be colored, this getter should return the color variant from ColorVariants enum,
   */
  getItemColorVariant?: (item: I | null) => ColorVariants;
  /**
   * Message, displayed, when there are no items in the select.
   */
  noItemsMessage?: ReactNode;
  /**
   * Message, displayed, when there are no items found with search
   */
  noItemsFoundMessage?: ReactNode;
  /**
   * If passed, renders a custom select toggle button inside of the default empty ul
   */
  renderToggleButton?: (
    props: Pick<
      UseComboboxReturnValue<I>,
      'isOpen' | 'getToggleButtonProps' | 'openMenu' | 'closeMenu'
    >
  ) => ReactNode;

  /**
   * Value of the select search input
   */
  search?: string;
  /**
   * Default value of the select search input
   */
  defaultSearch?: string;
  /**
   * Should select component use search (default is true)
   */
  withSearch?: boolean;
  /**
   * Handle search input value change
   */
  onSearchChange?: (search: string) => void;
  /**
   * Pass this prop, if you need to define custom search logic, or rely in backend search
   */
  isItemMatchingSearch?: (item: I, search: string) => boolean;

  /**
   * Pass this props, if select should work in async mode
   */
  asyncProps?: Pick<AsyncListProps<I>, keyof UseInfiniteScrollProps> &
    Partial<Pick<AsyncListState<I, undefined>, 'isFetchingMore'>>;
}

interface SingleSelectProps {
  /**
   * If passed, overrides value prop by finding an item by getItemValue that match the rawValue
   */
  rawValue?: string | number | boolean | null;
}

interface MultiSelectProps {
  /**
   * If passed, overrides value prop by finding an item by getItemValue that match the rawValue
   */
  rawValue?: (string | number | boolean | null)[];
}

/**
 * Select component props
 */
export type SelectProps<I extends SelectItem = SelectItem> =
  WithIsMultiFieldBaseProps<
    I,
    BaseFieldProps<I | undefined> & SingleSelectProps,
    BaseFieldProps<I[]> & MultiSelectProps
  > &
    SelectBaseProps<I>;

/**
 * Generic shortcut for partial select props
 */
export type PartialSelectProps<I extends SelectItem = SelectItem> =
  WithIsMultiFieldBaseProps<
    I,
    Partial<BaseFieldProps<I | undefined>> & Partial<SingleSelectProps>,
    Partial<BaseFieldProps<I[]>> & Partial<MultiSelectProps>
  > &
    Partial<SelectBaseProps<I>>;

/**
 * Select props with all partial values, but with required name, useful for creating select fabrics
 */
export type PartialSelectPropsWithName<I extends SelectItem = SelectItem> =
  Pick<SelectProps<I>, 'name'> &
    WithIsMultiFieldBaseProps<
      I,
      Omit<Partial<BaseFieldProps<I | undefined>>, 'name'> &
        Partial<SingleSelectProps>,
      Omit<Partial<BaseFieldProps<I[]>>, 'name'> & Partial<MultiSelectProps>
    > &
    Partial<SelectBaseProps<I>>;

/**
 * Workaround for typing generic HOC for Select
 */
export type RenderSelect = <I extends SelectItem = SelectItem>(
  props: SelectProps<I>
) => React.ReactElement;
