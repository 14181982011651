import { ReactNode } from 'react';

import R from 'ramda';

import { Skeleton, SkeletonPlaceholder } from '~/shared/components/Skeleton';

import { useAsyncList } from './hooks/useAsyncList';
import { UseAsyncListProps } from './types';

export interface AsyncListProps<
  Item,
  SkeletonItemsCount extends number | undefined = number,
> extends UseAsyncListProps<Item, SkeletonItemsCount> {
  /**
   * Render prop for rendering single item in the list
   */
  renderItem: (
    item: Item | SkeletonPlaceholder,
    index: number,
    array: (Item | SkeletonPlaceholder)[]
  ) => ReactNode;

  /**
   * You can pass this render prop to render a custom wrapper for the items
   */
  renderItemsWrapper?: (items: ReactNode) => ReactNode;
}

export const AsyncList = <Item extends any>({
  renderItem,

  renderItemsWrapper = R.identity,
  filtersElement,

  ...useAsyncListProps
}: AsyncListProps<Item>) => {
  const {
    isSkeletonLoading,
    loaderElement,

    isNoItems,
    noItemsMessageElement,

    isItemsNotCreated,

    itemsToRender,
  } = useAsyncList(useAsyncListProps);

  return (
    <>
      {!isItemsNotCreated && filtersElement}

      {noItemsMessageElement}

      {!isNoItems && (
        <Skeleton withDelay isLoading={isSkeletonLoading}>
          {renderItemsWrapper(
            <>
              {itemsToRender.map(renderItem)}

              {loaderElement}
            </>
          )}
        </Skeleton>
      )}
    </>
  );
};

export * from './hooks';
export * from './types';
export * from './helpers';
