import React from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';

import { EnumStrings } from '~/~legacy/strings/enumStrings';
import { MaslovNamespaces } from '~/~legacy/types/namespaces';

import { ContextMenuButton } from '~/shared/components/ContextMenuButton';
import { MenuItemVariants } from '~/shared/components/Menu';
import { isSkeletonPlaceholder } from '~/shared/components/Skeleton';
import {
  Table,
  TableColumnConfig,
  TableThemes,
} from '~/shared/components/Table';

import { useConfirm } from '~/services/modals';

import {
  getEventId,
  isDefaultEvent,
  useDeleteEvent,
  useEditEventModal,
} from '~/entities/events';
import { EventFragment } from '~/entities/events/gql/fragments/event.graphql';

import styles from './index.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Events to display in the table
   */
  events: EventFragment[];
  /**
   * If true, show skeletons for events
   */
  isLoading?: boolean;
}

const EVENT_ROWS_SKELETONS_COUNT = 8;

const NAME_COLUMN_WIDTH_PX = 300;
const SHORTCODE_COLUMN_WIDTH_PX = 184;

export const EventsTable: React.FC<Props> = ({
  className,
  events,
  isLoading,
}) => {
  const { t } = useTranslation(MaslovNamespaces.enums);

  const confirmDelete = useConfirm();

  const { open: openEditEventModal } = useEditEventModal();

  const deleteEvent = useDeleteEvent();

  const columnConfigs: TableColumnConfig<EventFragment>[] = [
    {
      title: 'Название события',
      key: 'name',
      itemField: 'name',
      width: NAME_COLUMN_WIDTH_PX,
    },
    {
      title: 'Код',
      key: 'shortcode',
      renderCellContent: item =>
        !isSkeletonPlaceholder(item) && isDefaultEvent(item)
          ? item.shortcode
          : item.id,
      width: SHORTCODE_COLUMN_WIDTH_PX,
    },
    {
      title: 'Тип',
      key: 'kind',
      renderCellContent: item => t(`${EnumStrings.eventKinds}${item.kind}`),
      width: '1fr',
    },
  ];

  return (
    <Table<EventFragment>
      {...{
        theme: TableThemes.largeSecondary,
        className: clsx(className, 'min-w-full w-min'),
        items: events,
        isLoading,
        columnConfigs,
        getItemKey: getEventId,
        noItemsMessage: 'Нет событий для отображения',
        itemActionCellClassName: styles.itemAction,
        skeletonItemsCount: EVENT_ROWS_SKELETONS_COUNT,
        renderItemActions: item => {
          if (isSkeletonPlaceholder(item) || isDefaultEvent(item)) return null;

          return (
            <ContextMenuButton
              {...{
                tooltip: 'Действия с событием',
                items: [
                  {
                    content: 'Редактировать',
                    onPress: () => {
                      openEditEventModal({ eventToEdit: item });
                    },
                  },
                  {
                    variant: MenuItemVariants.destructive,
                    content: 'Удалить',
                    onPress: () => {
                      confirmDelete({
                        title: `Вы хотите удалить событие ${item.name}?`,
                        message: 'Это действие нельзя будет отменить.',
                        isDelete: true,
                      }).then(isConfirmed => {
                        if (!isConfirmed) return;
                        deleteEvent(item);
                      });
                    },
                  },
                ],
              }}
            />
          );
        },
      }}
    />
  );
};
