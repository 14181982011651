import React from 'react';

import R from 'ramda';
import * as yup from 'yup';

import { Input } from '~/shared/components/Input';

import {
  Form,
  InferSchemaWithDefaults,
  InferValidatedSchema,
  useForm,
} from '~/services/forms';
import { InjectedModalProps, Modal } from '~/services/modals';
import { useNotifications } from '~/services/notifications';

import formStyles from '~/styles/modules/form.module.scss';

import { InseminationSchemeFragment } from '../../gql/fragments/inseminationScheme.graphql';
import { useCreateInseminationSchemeMutation } from '../../gql/mutations/createInseminationScheme.graphql';
import { useUpdateInseminationSchemeMutation } from '../../gql/mutations/updateInseminationScheme.graphql';
import { updateInseminationSchemeFragment } from '../../helpers';

export interface EditInseminationSchemeModalProps
  extends InjectedModalProps<EditInseminationSchemeModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Editing insemination scheme, if not passed, a new one is created
   */
  inseminationScheme?: InseminationSchemeFragment;
}

const FORM_ID = 'EditInseminationSchemeForm';

const SCHEMA = yup.object({
  name: yup.string().default('').required(),
  code: yup.string().default(''),
});

type EditInseminationSchemeFormType = InferSchemaWithDefaults<typeof SCHEMA>;
type EditInseminationSchemeFormTransformedType = InferValidatedSchema<
  typeof SCHEMA
>;

export const EditInseminationSchemeModal: React.FC<
  EditInseminationSchemeModalProps
> = ({ className, inseminationScheme, close }) => {
  const { sendSuccessToast } = useNotifications();

  const isEditing = !!inseminationScheme;

  const formContext = useForm<
    EditInseminationSchemeFormType,
    EditInseminationSchemeFormTransformedType
  >({
    schema: SCHEMA,
    defaultValues: {
      ...SCHEMA.getDefault(),
      ...R.pick(
        ['name', 'code'],
        inseminationScheme ?? ({} as InseminationSchemeFragment)
      ),
    },
  });

  const [
    createInseminationScheme,
    { loading: isCreateInseminationSchemeLoading },
  ] = useCreateInseminationSchemeMutation();

  const [
    updateInseminationScheme,
    { loading: isUpdateInseminationSchemeLoading },
  ] = useUpdateInseminationSchemeMutation();

  const handleSubmit = async (
    form: EditInseminationSchemeFormTransformedType
  ) => {
    if (isEditing) {
      await updateInseminationScheme({
        variables: {
          id: inseminationScheme.id,
          input: form,
        },
        optimisticResponse: { updateInseminationScheme: null },
        update: updateInseminationSchemeFragment(
          inseminationScheme.id,
          draft => {
            draft.name = form.name;
            draft.code = form.code;
          }
        ),
      });
    } else {
      await createInseminationScheme({
        variables: {
          input: form,
        },
        refetchQueries: ['inseminationSchemes'],
      });
    }

    sendSuccessToast(
      isEditing ? 'Схема осеменения обновлена' : 'Схема осеменения создана'
    );

    close();
  };

  return (
    <Modal
      {...{
        className,
        title: isEditing
          ? 'Редактирование схемы осеменения'
          : 'Создание новой схемы осеменения',
        submitButtonProps: {
          form: FORM_ID,
          isLoading:
            isCreateInseminationSchemeLoading ||
            isUpdateInseminationSchemeLoading,
          children: isEditing ? 'Сохранить' : 'Создать',
        },
        isRequireExplicitClosing: formContext.formState.isDirty,
      }}
    >
      <Form
        {...{
          className: formStyles.singleColumnForm,
          formContext,
          id: FORM_ID,
          onSubmit: formContext.handleSubmit(handleSubmit),
        }}
      >
        <Input
          {...{
            name: 'name',
            label: 'Название',
          }}
        />
        <Input
          {...{
            name: 'code',
            label: 'Код',
          }}
        />
      </Form>
    </Modal>
  );
};
