import R from 'ramda';

import { makeAsyncSelectFormComponent } from '~/services/forms';
import { makeUseAsyncSelect } from '~/services/gql';

import { InseminationSchemeFragmentDoc } from '../../gql/fragments/inseminationScheme.graphql';
import { useInseminationSchemesQuery } from '../../gql/queries/inseminationSchemes.graphql';

/**
 * Select for a inseminationScheme
 */
export const useInseminationSchemeSelect = makeUseAsyncSelect({
  typeName: 'InseminationScheme',
  fragment: InseminationSchemeFragmentDoc,
  useQuery: useInseminationSchemesQuery,
  getItemsFromQueryData: data =>
    data.inseminationSchemes.edges.map(R.prop('node')),
  getPageInfoFromQueryData: data => data.inseminationSchemes.pageInfo,

  selectProps: {
    placeholder: 'Выберите схему осеменения',
    getItemValue: item => item.id,
    getItemText: item => item?.name ?? '',
    getItemDescription: item => item?.code ?? '',
  },
});

export const InseminationSchemeAsyncSelect = makeAsyncSelectFormComponent(
  useInseminationSchemeSelect
);
