import { VitalityFilter } from '@graphql-types';
import { createFileRoute } from '@tanstack/react-router';
import clsx from 'clsx';

import { getAsyncListState } from '~/shared/components/AsyncList';
import { Button, ButtonVariants } from '~/shared/components/Button';
import { ContextMenuButton } from '~/shared/components/ContextMenuButton';
import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';
import { IconVariants } from '~/shared/components/Icon';
import { wrapJsxIf } from '~/shared/helpers/render';

import { useEditCowModal } from '~/entities/cows';
import { CowsTable } from '~/entities/cows/components/CowsTable';
import { useCowsPaginatedQuery } from '~/entities/cows/hooks/useCowsPaginatedQuery';

import { PageHeader } from '~/features/layouts';

import { useAddCowByKeyModal, useMoveCowsModal } from '~/widgets/cowsMovement';

import layoutStyles from '~/styles/modules/layout.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

import { useUploadCowsCsvModal } from './modals';

const COW_ROW_SKELETON_COUNT = 12;

export const Route = createFileRoute('/$companyId/_layout/user/herd/cows/')({
  wrapInSuspense: true,
  component: HerdCowsPage,
});

function HerdCowsPage() {
  const navigate = Route.useNavigate();

  const { open: openAddCowByKeyModal } = useAddCowByKeyModal();
  const { open: openMoveCowsModal } = useMoveCowsModal();
  const { open: openEditCowModal } = useEditCowModal();
  const { open: openUploadCowsCsvModal } = useUploadCowsCsvModal();

  const { items: cowItems, ...asyncProps } = useCowsPaginatedQuery({
    variables: {
      vitalityFilter: VitalityFilter.All,
      search: '',
    },
  });

  const asyncListState = getAsyncListState({
    items: cowItems,
    skeletonItemsCount: COW_ROW_SKELETON_COUNT,
    isLoading: asyncProps.isLoading,
  });

  const tableElement = (
    <CowsTable
      {...{
        asyncListState,
        items: cowItems,
        className: panelStyles.panel,
        skeletonItemsCount: COW_ROW_SKELETON_COUNT,
        shouldNoItemsMessageHideTable: true,
        noItemsMessage: (
          <div
            className={clsx(
              layoutStyles.fillLeftoverHeightContainer,
              layoutStyles.limitedContainer,
              panelStyles.panel,
              'p-24 grid place-items-center'
            )}
          >
            <DataBlockedMessage
              {...{
                isLarge: true,
                className: 'p-24',
                message: 'Животные пока не добавлены',
              }}
            />
          </div>
        ),
        ...asyncProps,
      }}
    />
  );

  return (
    <>
      <PageHeader
        {...{
          className: layoutStyles.limitedContainer,
          title: 'Животные',
          rightContent: (
            <>
              <Button
                {...{
                  variant: ButtonVariants.secondary,
                  iconVariant: IconVariants.key,
                  onPress: () =>
                    navigate({
                      to: '/$companyId/user/herd/cows/moveCowsKeys',
                    }),
                }}
              >
                Список ключей
              </Button>
              <Button
                {...{
                  variant: ButtonVariants.secondary,
                  iconVariant: IconVariants.chevronRightCircle,
                  onPress: () => openMoveCowsModal(),
                }}
              >
                Переместить
              </Button>
              <ContextMenuButton
                {...{
                  buttonProps: {
                    children: 'Добавить',
                    iconVariant: IconVariants.plus,
                    variant: ButtonVariants.primary,
                    rightIconVariant: IconVariants.chevronDown,
                  },
                  items: [
                    {
                      onPress: openUploadCowsCsvModal,
                      content: 'Загрузить из файла',
                    },
                    {
                      onPress: openEditCowModal,
                      content: 'Создать новых',
                    },
                    {
                      onPress: openAddCowByKeyModal,
                      content: 'Добавить по ключу',
                    },
                  ],
                  isFunctionButton: false,
                }}
              />
            </>
          ),
        }}
      />

      {wrapJsxIf(
        tableElement,
        !asyncListState.isItemsNotCreated,
        <div
          className={clsx(
            panelStyles.largePanel,
            layoutStyles.limitedContainer,
            'p-24'
          )}
        />
      )}
    </>
  );
}
