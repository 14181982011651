import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type OperationFragment = { __typename: 'Operation', id: string, name: string, kind: Types.EventKindEnum };

export const OperationFragmentDoc = gql`
    fragment Operation on Operation {
  __typename
  id
  name
  kind
}
    `;