import { createFileRoute } from '@tanstack/react-router';
import R from 'ramda';

import { Button, ButtonVariants } from '~/shared/components/Button';
import { IconVariants } from '~/shared/components/Icon';
import { Loader } from '~/shared/components/Loader';
import {
  Table,
  TableColumnConfig,
  TableThemes,
} from '~/shared/components/Table';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { downloadFile } from '~/shared/helpers/downloadFile';
import { joinJsxArray } from '~/shared/helpers/render';
import { usePrint } from '~/shared/hooks/usePrint';

import { getCowIdentifier } from '~/entities/cows';
import { getPenGroupIdentifier } from '~/entities/penGroups';

import { PageHeader } from '~/features/layouts';

import layoutStyles from '~/styles/modules/layout.module.scss';

import { CowPlannedInjectionsFragment } from './gql/fragments/cowPlannedInjections.graphql';
import { useApplyCowPlannedInjectionsMutation } from './gql/mutations/applyCowPlannedInjections.graphql';
import { useExportCowPlannedInjectionsMutation } from './gql/mutations/exportCowPlannedInjections.graphql';
import { useCowPlannedInjectionsQuery } from './gql/queries/cowPlannedInjections.graphql';

export const Route = createFileRoute(
  '/$companyId/_layout/user/planned-injections/'
)({
  wrapInSuspense: true,
  component: PlannedInjectionsPage,
});

function PlannedInjectionsPage() {
  const navigate = Route.useNavigate();

  const { data: cowPlannedInjectionsData, loading: isLoading } =
    useCowPlannedInjectionsQuery();
  const cowPlannedInjections =
    cowPlannedInjectionsData?.cowPlannedInjections.edges.map(R.prop('node'));

  const [exportCowPlannedInjections, { loading: isExportInjectionsLoading }] =
    useExportCowPlannedInjectionsMutation();
  const [applyCowPlannedInjections, { loading: isApplyInjectionsLoading }] =
    useApplyCowPlannedInjectionsMutation();

  const { containerRef, contentRef, handlePrint } = usePrint();

  const columnConfigs: TableColumnConfig<
    CowPlannedInjectionsFragment,
    undefined
  >[] = [
    {
      title: 'Номер животного',
      key: 'cowIdentifier',
      renderCellContent: item => getCowIdentifier(item.cow),
    },
    {
      title: 'Госпитальная группа',
      key: 'protocolPenGroupName',
      renderCellContent: ({ cow }) => {
        return `№ ${getPenGroupIdentifier(cow.penGroup)} ${cow.penGroup.name}`;
      },
    },
    {
      title: 'Болезнь',
      key: 'diseaseName',
      itemField: 'diseaseName',
    },
    {
      title: 'Протокол',
      key: 'protocolName',
      itemField: 'protocolName',
    },
    {
      title: 'Длительность',
      key: 'expectedDaysOnProtocol',
      renderCellContent: item => `${item.expectedDaysOnProtocol} дней`,
    },
    {
      title: '№ дня протокола',
      key: 'protocolDayNumber',
      renderCellContent: item => `${item.protocolDayNumber} день`,
    },
    {
      title: 'Инъекции сегодня',
      key: 'injectionNames',
      renderCellContent: item => item.injectionNames.join(', '),
    },
    {
      title: 'Комментарии',
      key: 'protocolDayComments',
      renderCellContent: item => joinJsxArray(item.protocolDayComments, <br />),
    },
  ];

  return (
    <div className={layoutStyles.limitedContainer} ref={containerRef}>
      <PageHeader
        {...{
          title: 'Рабочий список',
          rightContent: (
            <>
              <Button
                {...{
                  iconVariant: IconVariants.download,
                  variant: ButtonVariants.secondary,
                  isLoading: isExportInjectionsLoading,
                  onPress: () => {
                    exportCowPlannedInjections().then(result => {
                      const file = result.data?.exportCowPlannedInjections;

                      if (file) {
                        downloadFile(
                          file.downloadUrl,
                          file.name,
                          file.extension
                        );
                      }
                    });
                  },
                }}
              >
                Скачать csv
              </Button>
              <Button
                {...{
                  variant: ButtonVariants.secondary,
                  iconVariant: IconVariants.printer,
                  onPress: handlePrint,
                }}
              >
                Напечатать
              </Button>
              <Button
                {...{
                  variant: ButtonVariants.secondary,
                  iconVariant: IconVariants.syringe,
                  isLoading: isApplyInjectionsLoading,
                  onPress: () => applyCowPlannedInjections(),
                }}
              >
                Проставить инъекции
              </Button>
            </>
          ),
        }}
      />
      {isLoading && <Loader />}

      {!isLoading && (
        <Table<CowPlannedInjectionsFragment, undefined, undefined>
          {...{
            ref: contentRef,
            theme: TableThemes.tertiary,
            className: 'min-w-full w-min',
            items: cowPlannedInjections,
            columnConfigs,
            isLoading,
            getItemKey: item => item.cowProtocol.id,
            printableTitle: 'Рабочий список ветеринара',
            noItemsMessage: (
              <div>
                <Typography variant={TypographyVariants.bodySmall}>
                  На сегодняшний день нет животных для инъекций
                </Typography>

                <Button
                  {...{
                    className: 'mt-16',
                    variant: ButtonVariants.secondary,
                    onPress: () => {
                      navigate({
                        to: '/$companyId/user/production-calendar',
                      });
                    },
                  }}
                >
                  Вернуться в календарь
                </Button>
              </div>
            ),
            shouldNoItemsMessageHideTable: true,
          }}
        />
      )}
    </div>
  );
}
