import * as yup from 'yup';

import { InferValidatedSchema, useForm } from '~/services/forms';
import { UseModalStepFormInterface } from '~/services/modals';

import { MAPPING_ITEM_SCHEMA } from '../constants';

interface Props {
  /**
   * Initial values for the OperationMappings form
   */
  defaultValues: OperationMappingsFormType;
  /**
   * Called, when the form is submitted
   */
  onSubmit: (form: OperationMappingsFormType) => void;
}

const FORM_ID = 'OperationMappingsForm';

const SCHEMA = yup.object({
  operationMappings: yup.array(MAPPING_ITEM_SCHEMA).default([]),
});

/**
 * Form for editing operation mapping items
 */
export type OperationMappingsFormType = InferValidatedSchema<typeof SCHEMA>;

export const useOperationMappingsForm = ({
  defaultValues,
  onSubmit: handleSubmit,
}: Props): UseModalStepFormInterface<OperationMappingsFormType> => {
  const formContext = useForm<OperationMappingsFormType>({
    schema: SCHEMA,
    defaultValues: {
      ...SCHEMA.getDefault(),
      ...defaultValues,
    },
  });

  return {
    formContext,
    formId: FORM_ID,
    formProps: {
      formContext,
      id: FORM_ID,
      onSubmit: formContext.handleSubmit(handleSubmit),
    },
  };
};
