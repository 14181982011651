import React, { ReactNode } from 'react';

import { useApolloClient } from '@apollo/client';

import clsx from 'clsx';
import R from 'ramda';

import { Icon, IconVariants } from '~/shared/components/Icon';
import {
  isSkeletonPlaceholder,
  SkeletonPlaceholder,
} from '~/shared/components/Skeleton';
import {
  FILLER_COLUMN_CONFIG,
  Table,
  TableThemes,
} from '~/shared/components/Table';
import { Typography } from '~/shared/components/Typography';
import { formatInt, formatNumber } from '~/shared/helpers/number';

import { readSourceFieldFragment } from '~/entities/blueprintSourceFields';
import { CustomMilkingReportChartFragment } from '~/entities/customMilkingReports/gql/fragments/customMilkingReportChart.graphql';

import {
  SizeVariants,
  TypographyVariants,
} from '~/styles/__generated__/token-variants';

import {
  CUSTOM_MILKING_REPORT_Y_AXIS_FIELD_CONFIGS,
  CUSTOM_MILKING_REPORT_Y_AXIS_FIELD_GROUPINGS_DICT,
} from '../../../../constants';
import { formatDatasetLabel, formatYAxisFieldName } from '../../../../helpers';
import { CustomMilkingReportSettingsFormType } from '../../../../types';
import { getReportDatasets, getXAxisLabels, getXAxisName } from '../../helpers';
import {
  CustomMilkingReportCellData,
  CustomMilkingReportColumnConfig,
  CustomMilkingReportRowConfig,
} from './types';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Report data to render as table
   */
  reportData: CustomMilkingReportChartFragment | SkeletonPlaceholder;
  /**
   * Current settings of the report
   */
  requestedSettings: CustomMilkingReportSettingsFormType;
}

const TITLE_COLUMN_WIDTH_PX = 240;

export const CustomMilkingReportTable: React.FC<Props> = ({
  className,
  reportData,
  requestedSettings,
}) => {
  const client = useApolloClient();

  const nestedColumnNames = getXAxisLabels(reportData);

  const columnConfigs: CustomMilkingReportColumnConfig[] = [
    {
      key: 'title',
      title:
        (requestedSettings.grouping &&
          readSourceFieldFragment(
            client,
            requestedSettings.grouping.masterBlueprintSourceFieldID
          )?.name) ??
        'Показатели',
      renderCellContent: R.prop('firstColumnContent'),
      width: TITLE_COLUMN_WIDTH_PX,
      isSticky: true,
    },
    {
      key: 'data',
      title: getXAxisName(requestedSettings),
      nestedColumns: nestedColumnNames.map((columnName, columnIndex) => ({
        key: `${columnName}__${columnIndex}`,
        title: columnName.toString(),
        columnClassName: 'text-right',
        renderCellContent: row => row.renderCellContent?.({ columnIndex }),
      })),
    },
    FILLER_COLUMN_CONFIG,
  ];

  const reportDatasets = getReportDatasets(reportData);

  const rowConfigs: CustomMilkingReportRowConfig[] = reportDatasets.flatMap(
    (dataset, rowIndex) => {
      const rowId = isSkeletonPlaceholder(dataset)
        ? dataset.id
        : `${dataset.datasetLabel.field.kind}__${rowIndex}`;

      if (!dataset.datasetLabel) {
        return [{ id: rowId }];
      }

      const yAxisField = dataset.datasetLabel.field;

      const fieldName = formatYAxisFieldName(yAxisField.kind);
      const fieldGroupBy =
        (yAxisField.groupBy &&
          CUSTOM_MILKING_REPORT_Y_AXIS_FIELD_GROUPINGS_DICT[
            yAxisField.groupBy
          ]) ??
        '';
      const fieldNameWithGroupBy = `${fieldGroupBy}${fieldGroupBy ? ' из ' : ''}${fieldName}`;

      let firstColumnContent: ReactNode = fieldNameWithGroupBy;

      if (dataset.datasetLabel.__typename !== 'CustomMilkingReportDataset') {
        const formattedDatasetLabel = formatDatasetLabel(dataset.datasetLabel);

        firstColumnContent = (
          <>
            <div>{formattedDatasetLabel}</div>
            <Typography
              variant={TypographyVariants.descriptionLarge}
              className="text-muted"
            >
              {firstColumnContent}
            </Typography>
          </>
        );
      }

      return [
        {
          id: rowId,
          firstColumnContent,
          renderCellContent: ({ columnIndex }) => {
            if (isSkeletonPlaceholder(dataset) || !dataset.datasetLabel) {
              return null;
            }
            return formatNumber(
              dataset.dataPoints[columnIndex],
              CUSTOM_MILKING_REPORT_Y_AXIS_FIELD_CONFIGS[yAxisField.kind]
                .precision
            );
          },
        },
        {
          id: `${rowId}__cowCounts`,
          firstColumnContent: (
            <div className="flex items-center text-soft">
              <Icon
                {...{
                  tooltip: 'Число животных',
                  variant: IconVariants.cow,
                  size: SizeVariants.size20,
                }}
              />
            </div>
          ),
          rowClassName: 'background-neutral-opaque-container-soft',
          renderCellContent: ({ columnIndex }) => {
            if (isSkeletonPlaceholder(dataset) || !dataset.datasetLabel) {
              return null;
            }
            return formatInt(dataset.cowsCounts[columnIndex]);
          },
        },
      ];
    }
  );

  return (
    <Table<CustomMilkingReportRowConfig, CustomMilkingReportCellData, undefined>
      {...{
        theme: TableThemes.largeSecondary,
        className: clsx(className, 'min-w-full w-min'),
        items: rowConfigs,
        columnConfigs,
        noItemsMessage: 'Нет данных для отображения',
        shouldHideScrollBarsForNoOverflow: false,
        getRowClassName: R.prop('rowClassName'),
        shouldHideLargeData: true,
      }}
    />
  );
};
