import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { InseminationSchemeFragmentDoc } from '../fragments/inseminationScheme.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InseminationSchemesQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type InseminationSchemesQuery = { __typename?: 'Query', inseminationSchemes: { __typename?: 'InseminationSchemeConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'InseminationSchemeEdge', cursor: string, node: { __typename: 'InseminationScheme', id: string, name: string, code: string, isDefault: boolean } }> } };


export const InseminationSchemesDocument = gql`
    query inseminationSchemes($search: String, $first: Int, $after: String) {
  inseminationSchemes(first: $first, search: $search, after: $after) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...InseminationScheme
      }
    }
  }
}
    ${InseminationSchemeFragmentDoc}`;

/**
 * __useInseminationSchemesQuery__
 *
 * To run a query within a React component, call `useInseminationSchemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInseminationSchemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInseminationSchemesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useInseminationSchemesQuery(baseOptions?: Apollo.QueryHookOptions<InseminationSchemesQuery, InseminationSchemesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InseminationSchemesQuery, InseminationSchemesQueryVariables>(InseminationSchemesDocument, options);
      }
export function useInseminationSchemesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InseminationSchemesQuery, InseminationSchemesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InseminationSchemesQuery, InseminationSchemesQueryVariables>(InseminationSchemesDocument, options);
        }
export function useInseminationSchemesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<InseminationSchemesQuery, InseminationSchemesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InseminationSchemesQuery, InseminationSchemesQueryVariables>(InseminationSchemesDocument, options);
        }
export type InseminationSchemesQueryHookResult = ReturnType<typeof useInseminationSchemesQuery>;
export type InseminationSchemesLazyQueryHookResult = ReturnType<typeof useInseminationSchemesLazyQuery>;
export type InseminationSchemesSuspenseQueryHookResult = ReturnType<typeof useInseminationSchemesSuspenseQuery>;
export type InseminationSchemesQueryResult = Apollo.QueryResult<InseminationSchemesQuery, InseminationSchemesQueryVariables>;