import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { OperationFragmentDoc } from '../fragments/operation.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateOperationMutationVariables = Types.Exact<{
  input: Types.CreateOperationInput;
}>;


export type CreateOperationMutation = { __typename?: 'Mutation', createOperation: { __typename: 'Operation', id: string, name: string, kind: Types.EventKindEnum } };


export const CreateOperationDocument = gql`
    mutation createOperation($input: CreateOperationInput!) {
  createOperation(input: $input) {
    ...Operation
  }
}
    ${OperationFragmentDoc}`;
export type CreateOperationMutationFn = Apollo.MutationFunction<CreateOperationMutation, CreateOperationMutationVariables>;

/**
 * __useCreateOperationMutation__
 *
 * To run a mutation, you first call `useCreateOperationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOperationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOperationMutation, { data, loading, error }] = useCreateOperationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOperationMutation(baseOptions?: Apollo.MutationHookOptions<CreateOperationMutation, CreateOperationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOperationMutation, CreateOperationMutationVariables>(CreateOperationDocument, options);
      }
export type CreateOperationMutationHookResult = ReturnType<typeof useCreateOperationMutation>;
export type CreateOperationMutationResult = Apollo.MutationResult<CreateOperationMutation>;
export type CreateOperationMutationOptions = Apollo.BaseMutationOptions<CreateOperationMutation, CreateOperationMutationVariables>;