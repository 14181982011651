import { useCallback } from 'react';

import { BlueprintRoleColor, RunType, ViewKindEnum } from '@graphql-types';
import { createFileRoute } from '@tanstack/react-router';
import dayjs from 'dayjs';
import R from 'ramda';

import { getAsyncListState } from '~/shared/components/AsyncList';
import { wrapConditionalArrayElement } from '~/shared/helpers/array';
import {
  normalizeToArrayOrUndefined,
  normalizeToValueOrUndefined,
} from '~/shared/helpers/normalize';
import { getTmpId } from '~/shared/helpers/string';

import { formatDateForBackend } from '~/services/dateTime';
import {
  makeDeleteFragmentFromQuery,
  makeDeleteQueriesByNameWithoutVariables,
} from '~/services/gql';
import { WithSearchParamsValidation } from '~/services/navigation';

import { BlueprintFragment } from '~/entities/blueprints/gql/fragments/blueprint.graphql';
import { useDeleteBlueprintMutation } from '~/entities/blueprints/gql/mutations/deleteBlueprint.graphql';

import {
  BLUEPRINT_TILES_DEFAULT_COUNT,
  BlueprintsPageHeader,
  BlueprintsSearchParams,
  BlueprintTilesList,
  useBlueprintFilters,
} from '~/widgets/blueprintsList';

import {
  CalendarEntriesDocument,
  CalendarEntriesQuery,
  CalendarEntriesQueryVariables,
} from './gql/queries/calendarEntries.graphql';
import { useCalendarEntriesPaginatedQuery } from './hooks';

// We have special logic for planned injections,
// but we display them as a normal blueprint card
const PLANNED_INJECTIONS_BLUEPRINT: BlueprintFragment = {
  __typename: 'Blueprint',
  id: getTmpId('plannedInjections'),
  name: 'Рабочий список',
  description: 'Список инъекций на текущий день',
  viewSettings: {
    __typename: 'BlueprintViewSettings',
    kind: ViewKindEnum.Table,
  },
  roles: [
    {
      __typename: 'BlueprintRole',
      id: getTmpId('vet'),
      name: 'Ветеринар',
      color: BlueprintRoleColor.Green,
    },
  ],
};

export const Route = createFileRoute(
  '/$companyId/_layout/user/production-calendar/'
)({
  wrapInSuspense: true,
  component: ProductionCalendarPage,
  validateSearch: ({
    search,
    viewKind,
    roleIds,
  }: WithSearchParamsValidation<BlueprintsSearchParams>) => ({
    roleIds: Array.isArray(roleIds) ? roleIds : [],
    viewKind: viewKind ?? undefined,
    search: search ?? '',
  }),
});

const PRODUCTION_CALENDAR_RUN_TYPE = RunType.Schedule;

function ProductionCalendarPage() {
  const {
    roleIds,
    viewKind,
    debouncedSearch,
    blueprintsDate,
    isSearchActive,
    filtersElement,
  } = useBlueprintFilters(PRODUCTION_CALENDAR_RUN_TYPE);

  // Prepare calendar items data
  const queryVariables = {
    since: formatDateForBackend(blueprintsDate, true),
    till: formatDateForBackend(dayjs(blueprintsDate).add(1, 'day'), true),
    roleIDs: normalizeToArrayOrUndefined(roleIds),
    search: normalizeToValueOrUndefined(debouncedSearch),
    viewKind: normalizeToValueOrUndefined(viewKind),
  };

  const { items: calendarEntries, ...asyncProps } =
    useCalendarEntriesPaginatedQuery({
      variables: queryVariables,
    });

  // Blueprint deletion logic
  const [deleteBlueprint] = useDeleteBlueprintMutation();

  const handleDeleteBlueprint = useCallback((blueprintId: string) => {
    deleteBlueprint({
      variables: {
        id: blueprintId,
      },
      optimisticResponse: { deleteBlueprint: null },
      update: R.juxt([
        makeDeleteFragmentFromQuery<
          CalendarEntriesQuery,
          CalendarEntriesQueryVariables
        >({
          typeName: 'CalendarEntry',
          query: CalendarEntriesDocument,
          variables: queryVariables,
        })(blueprintId),
        makeDeleteQueriesByNameWithoutVariables(
          'calendarEntries',
          queryVariables
        ),
      ]),
      refetchQueries: ['calendarEntries'],
    });
  }, []);

  const isToday = dayjs(blueprintsDate).isSame(dayjs(), 'day');

  const asyncListState = getAsyncListState({
    items: [
      ...wrapConditionalArrayElement(
        !asyncProps.isLoading && isToday && PLANNED_INJECTIONS_BLUEPRINT
      ),
      ...calendarEntries.map(R.prop('blueprint')),
    ],
    skeletonItemsCount: BLUEPRINT_TILES_DEFAULT_COUNT,
    isSearchActive,
    isLoading: asyncProps.isLoading,
  });

  return (
    <>
      <BlueprintsPageHeader
        {...{
          title: 'Календарь',
          runType: PRODUCTION_CALENDAR_RUN_TYPE,
          shouldShowLayoutPrimaryButton: !asyncListState.isItemsNotCreated,
        }}
      />
      <BlueprintTilesList
        {...{
          asyncListState,
          filtersElement,
          runType: PRODUCTION_CALENDAR_RUN_TYPE,
          onDelete: handleDeleteBlueprint,
          ...asyncProps,
        }}
      />
    </>
  );
}
