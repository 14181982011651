import { useState } from 'react';

import R from 'ramda';
import * as yup from 'yup';

import { InferValidatedSchema, useForm } from '~/services/forms';
import { UseModalStepFormInterface } from '~/services/modals';

import { OperationFragment } from '../gql/fragments/operation.graphql';
import { useCreateOperationMutation } from '../gql/mutations/createOperation.graphql';
import { useUpdateOperationMutation } from '../gql/mutations/updateOperation.graphql';

/**
 * Props for editing an event with Operation kind
 */
interface Props {
  /**
   * If passed, this is the event to edit, creating new otherwise
   */
  operation?: OperationFragment;
  /**
   * Called, when the form is submitted
   */
  onSubmit?: (newEvent?: OperationFragment) => void;
}

const FORM_ID = 'EditOperationForm';

const SCHEMA = yup.object({
  name: yup.string().default('').required(),
});

/**
 * Form for editing an event with Operation kind
 */
export type EditOperationFormType = InferValidatedSchema<typeof SCHEMA>;

export const useOperationForm = ({
  operation,
  onSubmit,
}: Props): UseModalStepFormInterface<EditOperationFormType> => {
  const isEditing = !!operation;

  const [isLoading, setLoading] = useState(false);

  const [createOperation] = useCreateOperationMutation();
  const [updateOperation] = useUpdateOperationMutation();

  const formContext = useForm<EditOperationFormType>({
    schema: SCHEMA,
    defaultValues: {
      ...SCHEMA.getDefault(),
      ...R.pick(['name'], operation ?? ({} as OperationFragment)),
    },
  });

  const handleSubmit = async (input: EditOperationFormType) => {
    setLoading(true);
    try {
      let newEvent: OperationFragment | undefined;
      if (isEditing) {
        await updateOperation({
          variables: { id: operation.id, input },
          refetchQueries: ['events'],
        });
      } else {
        const res = await createOperation({
          variables: { input },
          refetchQueries: ['events'],
        });

        newEvent = res.data?.createOperation;
      }
      onSubmit?.(newEvent);
    } catch {
      setLoading(false);
    }
  };

  return {
    formContext,
    formId: FORM_ID,
    isLoading,
    formProps: {
      formContext,
      id: FORM_ID,
      onSubmit: formContext.handleSubmit(handleSubmit),
    },
  };
};
