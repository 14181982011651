import { BlueprintInputField } from '~/~legacy/services/BlueprintExecutionService';
import {
  DiseaseEventDTO,
  EventDTO,
  InjectionEventDTO,
  OperationDTO,
  ProtocolEventDTO,
} from '~/~legacy/services/EventsService';
import {
  AppBull,
  AppCalving,
  AppCow,
  AppEmployee,
  AppInsemination,
  AppInseminationScheme,
  AppPenGroup,
  AppSemenDose,
  UserFarm,
} from '~/~legacy/types/entities';

import { BullState, ValueKindEnum } from '~/shared/graphql';
import { PartialRecord } from '~/shared/types/utility';

import { ValueEditorKind } from '~/widgets/blueprintEdit';

export function getNewRow(length: number) {
  return [Math.random(), ...new Array(length)];
}

const getName = (val: string) => {
  return `автоматически подставленное значение [${val}]`;
};

function specialParseFloat(val: string): number {
  // indian code...  tired, so cant think of something smart :( maybe replace later
  const separator = String(1.1)[1];
  const noSpaces = val.replaceAll(' ', '');

  let commasNumber = noSpaces.split(',').length - 1;
  let pointsNumber = noSpaces.split('.').length - 1;

  let noDigitSplits = noSpaces;
  if (commasNumber > 1) {
    noDigitSplits = noSpaces.replaceAll(',', '');
  }

  if (pointsNumber > 1) {
    noDigitSplits = noSpaces.replaceAll('.', '');
  }

  commasNumber = noDigitSplits.split(',').length - 1;
  pointsNumber = noDigitSplits.split('.').length - 1;

  const separatorToReplace = commasNumber > pointsNumber ? ',' : '.';

  return Number.parseFloat(
    noDigitSplits.replaceAll(separatorToReplace, separator)
  );
}

const producers: PartialRecord<ValueEditorKind, (val: any) => any> = {
  [ValueKindEnum.Float]: val => {
    const result = specialParseFloat(val);
    return result;
  },
  [ValueKindEnum.CowId]: val => {
    const cow: AppCow = {
      id: val,
      name: `автоматически подставленная корова с id ${val}`,
      penGroupId: '',
      identifier: val,
      penGroupName: '',
    };
    return cow;
  },
  [ValueKindEnum.BullId]: val => {
    const item: AppBull = {
      id: val,
      name: getName(val),
      breed: '',
      category: '',
      dateOfBirth: '',
      number: '',
      registrationNumber: val,
      retiredAt: '',
      state: BullState.Bull,
      usdaNumber: '',
    };
    return item;
  },
  [ValueKindEnum.CalvingId]: val => {
    const item: AppCalving = {
      id: val,
      cow: {
        id: '',
        identifier: -1,
        name: '',
      },
      difficultyScore: -1,
      happenedAt: '',
      isAbortion: false,
    };
    return item;
  },
  [ValueKindEnum.DiseaseId]: val => {
    const item: DiseaseEventDTO = {
      id: val,
      name: getName(val),
    };
    return item;
  },
  [ValueKindEnum.EventId]: val => {
    const item: EventDTO = {
      id: val,
      name: getName(val),
      code: getName(val),
    };
    return item;
  },
  [ValueKindEnum.EmployeeId]: val => {
    const item: AppEmployee = {
      id: val,
      firstName: getName(val),
      lastName: '',
      middleName: '',
      number: '',
      comment: '',
    };
    return item;
  },
  [ValueKindEnum.FarmId]: val => {
    const item: UserFarm = {
      id: val,
      name: getName(val),
      companyId: '',
      number: '',
    };
    return item;
  },
  [ValueKindEnum.InjectionId]: val => {
    const item: InjectionEventDTO = {
      id: val,
      name: getName(val),
    };
    return item;
  },
  [ValueKindEnum.InseminationId]: val => {
    const item: AppInsemination = {
      id: val,
      bull: {
        id: '',
        name: '',
      },
      cow: {
        id: '',
        identifier: -1,
        name: '',
      },
      happenedAt: '',
      movedToGroupWithBullAt: '',
    };
    return item;
  },
  [ValueKindEnum.InseminationSchemeId]: val => {
    const item: AppInseminationScheme = {
      id: val,
      description: '',
      name: getName(val),
    };
    return item;
  },
  [ValueKindEnum.PenGroupId]: val => {
    const item: AppPenGroup = {
      id: val,
      name: getName(val),
      farmId: '',
      identifier: -1,
      isArtificialInsemination: false,
      isCalf: false,
      isDry: false,
      isHospital: false,
      isMilking: false,
      isUser: false,
      isWithBull: false,
      occupied: -1,
    };
    return item;
  },
  [ValueKindEnum.ProtocolId]: val => {
    const item: ProtocolEventDTO = {
      id: val,
      name: getName(val),
    };
    return item;
  },
  [ValueKindEnum.SemenDoseBatchId]: val => {
    const item: AppSemenDose = {
      id: val,
      bullId: '',
      bullName: '',
      batchNumber: '',
      bullRegNumber: '',
      deliveryDate: '',
      dosesCount: -1,
      studCode: '',
    };
    return item;
  },
  [ValueKindEnum.OperationId]: val => {
    const item: OperationDTO = {
      id: val,
      name: getName(val),
    };
    return item;
  },
};

const defaultGetter = (value: any) => value;

export function getEntity(value: any, input: BlueprintInputField): any {
  const getter = producers[input.valueKind] || defaultGetter;
  const entity = getter(value);
  return entity;
}
