import React, { ReactElement } from 'react';

import { ValueEditorArgs } from '~/~legacy/value-editors/types/valueEditorTypes';

import { NodeValueKindEnum, ValueKindEnum } from '~/shared/graphql';

import { ValueEditorKind } from '~/widgets/blueprintEdit';

const DateEditor = React.lazy(() =>
  import('./editors/DateEditor').then(component => ({
    default: component.DateEditor,
  }))
);

const FloatEditor = React.lazy(() =>
  import('./editors/FloatEditor').then(component => ({
    default: component.FloatEditor,
  }))
);

const PenGroupSelector = React.lazy(() =>
  import('./editors/PenGroupSelector').then(component => ({
    default: component.PenGroupSelector,
  }))
);

const VariableEditor = React.lazy(() =>
  import('./editors/VariableEditor').then(component => ({
    default: component.VariableEditor,
  }))
);

const IntEditor = React.lazy(() =>
  import('./editors/IntEditor').then(component => ({
    default: component.IntEditor,
  }))
);

const StringEditor = React.lazy(() =>
  import('./editors/StringEditor').then(component => ({
    default: component.StringEditor,
  }))
);

const IndexEditor = React.lazy(() =>
  import('./editors/IndexEditor').then(component => ({
    default: component.IndexEditor,
  }))
);

const DowEditor = React.lazy(() =>
  import('./editors/DowEditor').then(component => ({
    default: component.DowEditor,
  }))
);

const CowStateEditor = React.lazy(() =>
  import('./editors/CowStateEditor').then(component => ({
    default: component.CowStateEditor,
  }))
);

const InjectionIdSelector = React.lazy(() =>
  import('./editors/InjectionIdSelector').then(component => ({
    default: component.InjectionIdSelector,
  }))
);

const VoidEditor = React.lazy(() =>
  import('./editors/VoidEditor').then(component => ({
    default: component.VoidEditor,
  }))
);

const EventSelector = React.lazy(() =>
  import('./editors/EventSelector').then(component => ({
    default: component.EventSelector,
  }))
);

const ProtocolSelector = React.lazy(() =>
  import('./editors/ProtocolSelector').then(component => ({
    default: component.ProtocolSelector,
  }))
);

const DiseaseSelector = React.lazy(() =>
  import('./editors/DiseaseSelector').then(component => ({
    default: component.DiseaseSelector,
  }))
);

const CowSelector = React.lazy(() =>
  import('./editors/CowSelector').then(component => ({
    default: component.CowSelector,
  }))
);

const FarmSelector = React.lazy(() =>
  import('./editors/FarmSelector').then(component => ({
    default: component.FarmSelector,
  }))
);

const BullSelector = React.lazy(() =>
  import('./editors/BullSelector').then(component => ({
    default: component.BullSelector,
  }))
);

const CalvingSelector = React.lazy(() =>
  import('./editors/CalvingSelector').then(component => ({
    default: component.CalvingSelector,
  }))
);

const EmployeeSelector = React.lazy(() =>
  import('./editors/EmployeeSelector').then(component => ({
    default: component.EmployeeSelector,
  }))
);

const InseminationSchemeSelector = React.lazy(() =>
  import('./editors/InseminationSchemeSelector').then(component => ({
    default: component.InseminationSchemeSelector,
  }))
);

const SemenDoseSelector = React.lazy(() =>
  import('./editors/SemenDoseSelector').then(component => ({
    default: component.SemenDoseSelector,
  }))
);

const CowRetirementReasonSelector = React.lazy(() =>
  import('./editors/CowRetirementReasonSelector').then(component => ({
    default: component.CowRetirementReasonSelector,
  }))
);

const LimbSelector = React.lazy(() =>
  import('./editors/LimbSelector').then(component => ({
    default: component.LimbSelector,
  }))
);

const InseminationSelector = React.lazy(() =>
  import('./editors/InseminationSelector').then(component => ({
    default: component.InseminationSelector,
  }))
);

const BoolEditor = React.lazy(() =>
  import('./editors/BoolEditor').then(component => ({
    default: component.BoolEditor,
  }))
);

const OperationSelector = React.lazy(() =>
  import('./editors/OperationSelector').then(component => ({
    default: component.OperationSelector,
  }))
);

const BullRetirementReasonSelector = React.lazy(() =>
  import('./editors/BullRetirementReasonSelector').then(component => ({
    default: component.BullRetirementReasonSelector,
  }))
);

const LactationStateSelector = React.lazy(() =>
  import('./editors/LactationStateSelector').then(component => ({
    default: component.LactationStateSelector,
  }))
);

const UnknownEditor = React.lazy(() => {
  const component = (args: ValueEditorArgs<any>) => (
    <>Unknown type of the value [{args.returnValueKind || args.valueKind}]</>
  );
  return Promise.resolve({ default: component });
});

//  use Suspense in parent component to prevent flickering

export const EditorsMap: Record<
  ValueEditorKind,
  (args: ValueEditorArgs<any>) => ReactElement
> = {
  [NodeValueKindEnum.Variable]: args => <VariableEditor {...args} />,

  [ValueKindEnum.Bool]: args => <BoolEditor {...args} />,
  [ValueKindEnum.BullId]: args => <BullSelector {...args} />,
  [ValueKindEnum.BullRetirementReason]: args => (
    <BullRetirementReasonSelector {...args} />
  ),
  [ValueKindEnum.CalvingId]: args => <CalvingSelector {...args} />,
  [ValueKindEnum.CowId]: args => <CowSelector {...args} />,
  // Unknown ↓
  [ValueKindEnum.CowIds]: args => <UnknownEditor {...args} />,
  [ValueKindEnum.CowRetirementReason]: args => (
    <CowRetirementReasonSelector {...args} />
  ),
  [ValueKindEnum.Limb]: args => <LimbSelector {...args} />,
  [ValueKindEnum.CowState]: args => <CowStateEditor {...args} />,
  [ValueKindEnum.Date]: args => <DateEditor {...args} />,
  [ValueKindEnum.DiseaseId]: args => <DiseaseSelector {...args} />,
  [ValueKindEnum.Dow]: args => <DowEditor {...args} />,
  [ValueKindEnum.EventId]: args => <EventSelector {...args} />,
  // Unknown ↓
  [ValueKindEnum.EventIds]: args => <UnknownEditor {...args} />,
  [ValueKindEnum.EmployeeId]: args => <EmployeeSelector {...args} />,
  [ValueKindEnum.FarmId]: args => <FarmSelector {...args} />,
  [ValueKindEnum.Float]: args => <FloatEditor {...args} />,
  [ValueKindEnum.InjectionId]: args => <InjectionIdSelector {...args} />,
  [ValueKindEnum.Int]: args => <IntEditor {...args} />,
  [ValueKindEnum.Index]: args => <IndexEditor {...args} />,
  [ValueKindEnum.InseminationId]: args => <InseminationSelector {...args} />,
  [ValueKindEnum.InseminationSchemeId]: args => (
    <InseminationSchemeSelector {...args} />
  ),
  [ValueKindEnum.LactationIndex]: args => <IntEditor {...args} />,
  [ValueKindEnum.LactationState]: args => <LactationStateSelector {...args} />,
  // Unknown ↓  float is assumption
  [ValueKindEnum.Numeric]: args => <FloatEditor {...args} />,
  [ValueKindEnum.PenGroupId]: args => <PenGroupSelector {...args} />,
  [ValueKindEnum.ProtocolId]: args => <ProtocolSelector {...args} />,
  // Unknown ↓
  [ValueKindEnum.SemenDoseBatchId]: args => <SemenDoseSelector {...args} />,
  // [ValueKindEnum.SemenDoseId]: (args) => <SemenDoseSelector {...args} />,
  [ValueKindEnum.Text]: args => <StringEditor {...args} />,
  // Unknown ↓
  [ValueKindEnum.OperationId]: args => <OperationSelector {...args} />,
  [ValueKindEnum.Void]: args => <VoidEditor {...args} />,

  Unknown: args => <UnknownEditor {...args} />,
};
