import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type CowDiseaseFragment = { __typename: 'CowDisease', id: string, disease: { __typename: 'Disease', id: string, name: string, kind: Types.EventKindEnum } };

export const CowDiseaseFragmentDoc = gql`
    fragment CowDisease on CowDisease {
  __typename
  id
  disease {
    __typename
    id
    name
    kind
  }
}
    `;