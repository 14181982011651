import { ReactNode } from 'react';

import { LinkProps, RegisteredRouter } from '@tanstack/react-router';
import clsx from 'clsx';

import {
  Button,
  ButtonPropsWithoutRef,
  ButtonVariants,
} from '~/shared/components/Button';
import { IconVariants, RotateVariants } from '~/shared/components/Icon';
import { Skeleton, TextSkeletonSizes } from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { useArkaNavigation, useLayoutContext } from '~/services/navigation';

import styles from './index.module.scss';

/**
 * Basic props of a page header without navigation for easier extending
 */
export interface BasePageHeaderProps {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * If true, all header elements are wrapped with a loading Skeleton
   */
  isLoading?: boolean;

  /**
   * Page title
   */
  title?: string;
  /**
   * If true, wraps title in a loading Skeleton (default - isLoading)
   */
  isTitleLoading?: boolean;
  /**
   * If passed, renders additional badge near the title
   */
  titleBadge?: ReactNode;

  /**
   * Page description, if passed, rendered under title with soft text
   */
  description?: ReactNode;
  /**
   * If passed, renders fully custom node at the description place instead of default typography
   */
  descriptionContent?: ReactNode;
  /**
   * If true, renders description even if it is undefined (used for correct skeleton display)
   */
  isWithDescription?: boolean;
  /**
   * If true, wraps description in a loading Skeleton (default - isLoading)
   */
  isDescriptionLoading?: boolean;

  /**
   * Content to render on the right part of the header.
   * Automatically aligns it on the right and applies flex gap from the root node.
   */
  rightContent?: ReactNode;
  /**
   * If true, renders the primary button in the header,
   * otherwise it is usually rendered by other components
   * at the center on an empty page (default - true)
   */
  shouldShowLayoutPrimaryButton?: boolean;
  /**
   * Special type of rightContent for rendering a primary button,
   * that can be displayed in the header on at the center of the page
   */
  layoutPrimaryButtonProps?: Partial<ButtonPropsWithoutRef>;
  /**
   * If true, wraps right content in a loading Skeleton (default - isLoading)
   */
  isRightContentLoading?: boolean;
}

interface Props<LinkFrom extends string, LinkTo extends string>
  extends BasePageHeaderProps {
  /**
   * If passed, renders a back link with given props
   */
  backLinkProps?: LinkProps<'a', RegisteredRouter, LinkFrom, LinkTo>;
}

export const PageHeader = <LinkFrom extends string, LinkTo extends string>({
  className,
  backLinkProps,

  isLoading = false,

  title,
  isTitleLoading = isLoading,
  titleBadge,

  description,
  descriptionContent,
  isWithDescription: isWithDescriptionProp = false,
  isDescriptionLoading = isLoading,

  rightContent: rightContentProp,
  shouldShowLayoutPrimaryButton = true,
  layoutPrimaryButtonProps: layoutPrimaryButtonPropsProp,
  isRightContentLoading = isLoading,
}: Props<LinkFrom, LinkTo>) => {
  const { navigate } = useArkaNavigation();

  const {
    headerRightContent: headerRightContentContext,
    layoutPrimaryButtonProps: layoutPrimaryButtonPropsContext,
  } = useLayoutContext();

  const layoutPrimaryButtonProps =
    layoutPrimaryButtonPropsProp ?? layoutPrimaryButtonPropsContext;

  const hasRightContent =
    !!(rightContentProp ?? headerRightContentContext) ||
    (shouldShowLayoutPrimaryButton && !!layoutPrimaryButtonProps);
  const rightContent = (
    <>
      {rightContentProp ?? headerRightContentContext}
      {shouldShowLayoutPrimaryButton && !!layoutPrimaryButtonProps && (
        <Button
          {...layoutPrimaryButtonProps}
          key={layoutPrimaryButtonProps.key}
        />
      )}
    </>
  );

  const isWithDescription =
    isWithDescriptionProp || !!description || !!descriptionContent;

  const alignItemsClassName = isWithDescription
    ? 'items-start'
    : 'items-center';

  return (
    <div className={clsx(styles.root, alignItemsClassName, className)}>
      {backLinkProps && (
        <Button
          {...{
            variant: ButtonVariants.secondary,
            iconVariant: IconVariants.arrowUp,
            iconProps: {
              rotate: RotateVariants.left,
            },
            // TODO make button links, so we can render an actual a tag
            onPress: () => navigate(backLinkProps as LinkProps),
          }}
        />
      )}

      <div className="grid gap-4 mr-a">
        <Skeleton isLoading={isTitleLoading}>
          <Typography
            className={clsx(
              'flex items-center gap-12',
              !hasRightContent && 'py-6'
            )}
            variant={TypographyVariants.heading2}
          >
            {title}
            {titleBadge}
          </Typography>
        </Skeleton>

        {isWithDescription && (
          <Skeleton isLoading={isDescriptionLoading}>
            {!descriptionContent && (
              <Typography
                {...{
                  variant: TypographyVariants.bodySmall,
                  className: 'text-soft',
                  skeletonSize: TextSkeletonSizes.large,
                  skeletonProps: {
                    className: 'py-4',
                  },
                }}
              >
                {description}
              </Typography>
            )}
            {descriptionContent}
          </Skeleton>
        )}
      </div>

      {hasRightContent && (
        <Skeleton isLoading={isRightContentLoading}>
          <div className={clsx(styles.rightContent, alignItemsClassName)}>
            {rightContent}
          </div>
        </Skeleton>
      )}
    </div>
  );
};
