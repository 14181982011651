import { ModalRegistration } from '~/services/modals';

import {
  EditInseminationSchemeModal,
  EditInseminationSchemeModalProps,
} from '.';

export const registration: ModalRegistration<EditInseminationSchemeModalProps> =
  {
    Component: EditInseminationSchemeModal,
  };
