import { createFileRoute } from '@tanstack/react-router';
import clsx from 'clsx';

import { AsyncList, getAsyncListState } from '~/shared/components/AsyncList';
import {
  Button,
  ButtonProps,
  ButtonVariants,
} from '~/shared/components/Button';
import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';
import { IconVariants } from '~/shared/components/Icon';
import { normalizeToArrayOrUndefined } from '~/shared/helpers/normalize';

import { WithSearchParamsValidation } from '~/services/navigation';

import { FarmFilterSearchParams, useFarmsFilter } from '~/entities/farms';

import { PageHeader } from '~/features/layouts';

import {
  MilkingParlorCard,
  useEditMilkingParlorModal,
  useMilkingParlorsPaginatedQuery,
} from '~/widgets/milkingParlors';
import { MilkingParlorFragment } from '~/widgets/milkingParlors/gql/fragments/milkingParlor.graphql';

import contentGridStyles from '~/styles/modules/contentGrid.module.scss';
import layoutStyles from '~/styles/modules/layout.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

const MILKING_PARLORS_CARDS_DEFAULT_COUNT = 4;

export const Route = createFileRoute(
  '/$companyId/_layout/user/entities/milking-parlors/'
)({
  wrapInSuspense: true,
  component: MilkingParlorsPage,
  validateSearch: ({
    farmId,
  }: WithSearchParamsValidation<FarmFilterSearchParams>) => ({
    farmId: farmId ?? null,
  }),
});

function MilkingParlorsPage() {
  const navigate = Route.useNavigate();

  const { open: openEditMilkingParlorModal } = useEditMilkingParlorModal();

  const { farmId, renderFarmsSelectElement } = useFarmsFilter(false);

  const queryVariables = {
    farmIDs: normalizeToArrayOrUndefined(farmId),
  };

  const { items: milkingParlorItems, ...asyncProps } =
    useMilkingParlorsPaginatedQuery({
      variables: queryVariables,
      skip: !farmId,
    });

  const addButtonProps = {
    iconVariant: IconVariants.plus,
    children: 'Добавить зал',
    onPress: () =>
      openEditMilkingParlorModal({
        queryVariables,
      }),
  } satisfies ButtonProps;

  const filtersElement = (
    <div className={clsx('flex gap-16 mb-24', layoutStyles.limitedContainer)}>
      {renderFarmsSelectElement({
        className: 'default-control',
      })}
    </div>
  );

  const asyncListState = getAsyncListState({
    items: milkingParlorItems,
    skeletonItemsCount: MILKING_PARLORS_CARDS_DEFAULT_COUNT,
    isSearchActive: !!farmId,
    isLoading: !farmId || asyncProps.isLoading,
  });

  return (
    <>
      <PageHeader
        {...{
          className: layoutStyles.limitedContainer,
          title: 'Доильные залы',
          rightContent: !asyncListState.isItemsNotCreated && (
            <>
              <Button
                {...{
                  className: 'ml-a',
                  variant: ButtonVariants.secondary,
                  isDisabled: !farmId,
                  iconVariant: IconVariants.clock,
                  onPress: () => {
                    if (!farmId) return;

                    navigate({
                      to: '/$companyId/user/entities/milking-parlors/$farmId/schedule',
                      params: prev => ({ ...prev, farmId }),
                    });
                  },
                }}
              >
                Планирование доений
              </Button>
              <Button key="addMilkingParlor" {...addButtonProps} />
            </>
          ),
        }}
      />
      <AsyncList<MilkingParlorFragment>
        {...{
          asyncListState,
          filtersElement,
          renderItemsWrapper: items => (
            <div
              className={clsx(
                layoutStyles.limitedContainer,
                contentGridStyles.autoGridTiles
              )}
            >
              {items}
            </div>
          ),
          renderNoItemsMessage: noItemsMessage => (
            <div
              className={clsx(
                layoutStyles.fillLeftoverHeightContainer,
                layoutStyles.limitedContainer,
                panelStyles.panel,
                'p-24 grid place-items-center'
              )}
            >
              {noItemsMessage}
            </div>
          ),
          noItemsMessage: (
            <DataBlockedMessage
              {...{
                isLarge: true,
                className: 'p-24',
                message: 'Доильные залы пока не добавлены',
                buttonProps: addButtonProps,
              }}
            />
          ),
          noSearchItemsMessage: (
            <DataBlockedMessage
              {...{
                isLarge: true,
                className: 'p-24',
                message: 'Доильные залы не найдены',
                description: 'По вашему запросу нет доильных залов',
              }}
            />
          ),
          renderItem: milkingParlor => (
            <MilkingParlorCard
              key={milkingParlor.id}
              {...{
                milkingParlor,
                queryVariables,
              }}
            />
          ),
          ...asyncProps,
        }}
      />
    </>
  );
}
